import React, { useContext } from 'react'
import { ModalContext } from '../contexts/ModalContext'
import { VideoPlayerContext } from '../contexts/VideoPlayerContext'
import VideoPlayBtn from './VideoPlayBtn'
import parse from 'html-react-parser'

import './../scss/VideoCard.scss'
import InlineLink from './InlineLink'
import TextLink from './TextLink'

function VideoCard({ video, partnerUrl = null, description = null }) {
    const { setIsModalActive  } = useContext(ModalContext)
    const { setBcId } = useContext(VideoPlayerContext)
    
    const playVideo = (bcId) => {
        setBcId(bcId)
        setIsModalActive(true);
    }

    return (
        <figure className='video-card'>
            <div className='video-card__img-wrapper' onClick={() => playVideo(video.id)}>
                <img src={video.poster} alt="" className='video-card__img'/>
                <VideoPlayBtn bcId={video.id} size="1rem" title="Watch Video"/>
                <div className='video-card__hover-background'></div>
            </div>
            <figcaption className='video-card__caption'>
                <div className='video-card__caption-lead'>Watch Now</div>
                <div className='video-card__title'>{(video.custom_fields.networks_site_video_title !== undefined) ? parse(video.custom_fields.networks_site_video_title) : parse(video.name)}</div>
                { (description !== null) && 
                    <div className='video-card__description'>
                        {parse(description)}
                    </div>
                }
                <TextLink clickHandler={() => playVideo(video.id)} text="Watch Video" type="readmore" />
                { (partnerUrl !== null && 
                    <InlineLink link={video.partner_url} text="visit partner website" />
                )}
            </figcaption>
        </figure>
    )
}

export default VideoCard
