import React from 'react'
import BaseLayout from './layouts/BaseLayout'
import {Route, Routes} from 'react-router-dom'
import Home from './pages/Home'
import Advertising from './pages/Advertising'
import Distribution from './pages/Distribution'
import AnimationPreview from './pages/AnimationPreview'
import News from './pages/news/News'
import NewsItem from './pages/news/NewsItem'
import Brand from './pages/Brand'
import BrandStations from './pages/BrandStations'
import PageNotFound from './pages/PageNotFound'

import './scss/Base.scss'

function App() {
    return (
        <BaseLayout>
            <Routes>
                <Route exact path="/" element={<Home />} key="home"/>
                <Route exact path="/brand/:slug" element={<Brand />} />
                <Route exact path="/brand/:slug/stations" element={<BrandStations />} />
                <Route exact path="/special/:slug" element={<Brand resourceName="specials"/>} />
                <Route exact path="/show/:slug" element={<Brand resourceName="shows"/>} />
                <Route exact path="/advertising" element={<Advertising />} />
                <Route exact path="/distribution" element={<Distribution />} />
                <Route exact path="/animations-preview" element={<AnimationPreview />} />
                <Route exact path="/news/:id" element={<NewsItem />} />
                <Route exact path="/news" element={<News />} />
                <Route path="*" element={<PageNotFound />} />
            </Routes>
        </BaseLayout>
    )
}

export default App
