import React from 'react'
import PageHeroDefault from './PageHeroDefault'

import './../scss/PageHero.scss'

function PageHero({ resoure, page = 'default'}) {
    return (<>
        <header className={`page-hero`}>
            {(page === 'default') &&
                <PageHeroDefault data={resoure} />
            }
            <picture className={`page-hero__img-wrap`}>
                <source
                    media="(min-width: 1600px)"
                    srcSet={resoure.images.hero}
                />
                <source 
                    media="(min-width: 1200px)" 
                    srcSet={resoure.images.hero} 
                />
                <source 
                    media="(min-width: 800px)" 
                    srcSet={resoure.images.hero} 
                />
                <source 
                    media="(min-width: 300px)" 
                    srcSet={resoure.images.hero} 
                />
                <source 
                    media="(min-width: 1px)" 
                    srcSet={resoure.images.hero} 
                />
                <img src={resoure.images.hero} alt="" />
            </picture>
            <picture className={`page-hero__img-wrap page-hero--mobile`}>
                <source
                    media="(min-width: 1600px)"
                    srcSet={resoure.images.hero_mobile}
                />
                <source 
                    media="(min-width: 1200px)" 
                    srcSet={resoure.images.hero_mobile} 
                />
                <source 
                    media="(min-width: 800px)" 
                    srcSet={resoure.images.hero_mobile} 
                />
                <source 
                    media="(min-width: 300px)" 
                    srcSet={resoure.images.hero_mobile} 
                />
                <source 
                    media="(min-width: 1px)" 
                    srcSet={resoure.images.hero_mobile} 
                />
                <img src={resoure.images.hero_mobile} alt="" />
            </picture>
        </header>
    </>)
}

export default PageHero
