import React from 'react';
import VideoPlayerOnPage from './VideoPlayerOnPage';

function ArticleVideo({ videoId }) {
    return (
        <div className='article-video__wrapper'>
            <VideoPlayerOnPage bcId={videoId} />  
        </div>
    )
}

export default ArticleVideo;
