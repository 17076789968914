import React from 'react'

function SocialCarousel({ resource }) {
    return (
        <section className='social-caraousel'>
            This is the social carousel <br/>
            ================================
        </section>
    )
}

export default SocialCarousel
