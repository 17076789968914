import React from 'react';
import ShowcaseExternal from './ShowcaseExternal';
import ShowcaseInternal from './ShowcaseInternal';
import ShowcaseVideo from './ShowcaseVideo';

function ShowcaseItem({ item }) {
    return (<>
        { (item.type === 'External URL') && <ShowcaseExternal item={item}/> }
        { (item.type === 'Video') && <ShowcaseVideo item={item} /> }
        { (item.type === 'Internal Article') && <ShowcaseInternal item={item} /> }
    </>)
}

export default ShowcaseItem;
