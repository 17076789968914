import React from 'react';
import TextLink from './TextLink';
import parse from 'html-react-parser'

function ShowcaseInternal({ item }) {
    return (
       
            <figure className='video-card'>
                 <a href={item.url} className='video-card__img-wrapper'>
                    <img src={item.images.thumbnail} alt="" className='video-card__img'/>
                    <div className='video-card__hover-background'></div>
                </a>
                <figcaption className='video-card__caption'>
                    <div className='video-card__title'>{parse(item.title)}</div>
                    { (item.description !== null) && 
                        <div className='video-card__description'>
                            {parse(item.description)}
                        </div>
                    }
                    <TextLink text="Read More" type="readmore" link={item.url} />
                </figcaption>
            </figure>
    );
}

export default ShowcaseInternal;
