import React from 'react'
import { useQuery } from 'react-query';
import { getResourceList } from '../models/ApiModel';
import Working from './Working';
import BrandProgrammingItemLink from './BrandProgrammingItemLink';
import BrandProgrammingItem from './BrandProgrammingItem';

import './../scss/BrandProgramming.scss'

function BrandProgramming({ brandSlug, sectionInfo }) {
    const { isSuccess, isLoading, isError, data } = useQuery([`brandShows:${brandSlug}`, {
        resourceName: 'shows', 
        resourceId: { slug: brandSlug },
        filters: {
            category: brandSlug,
        },
    }], getResourceList)

    return (
        <section className='brand-programming page-section-padding'>
            <h2 className='gradient-header'>Programming</h2>
            <h3 className='headline headline--center'>{sectionInfo?.description_title ?? ''}</h3> 
            <div className='constrain-content'>
            {isLoading && <Working />}     
            {isError && <div></div>}     
            {isSuccess &&  
                <div className='programming__grid'>                    
                    {data.map((show) => {
                        if (show.disable_click) return <BrandProgrammingItem show={show} key={`show-${show.id}`}/>
                        
                        return <BrandProgrammingItemLink  show={show} key={`show-${show.id}`}/> 
                    })}
                </div>
            }
            </div>
        </section>
    )
}

export default BrandProgramming;
