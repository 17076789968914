import React from 'react';
import SpotLightExternalLink from './SpotLightExternalLink';
import SpotlightInternalLink from './SpotlightInternalLink';
import SpotlightVideo from './SpotlightVideo';

import './../scss/Spotlight.scss'

function Spotlight({ sectionInfo }) {
    return (
        <section className='spotlight page-section-padding' style={{
            backgroundImage: `var(--primary-gradient-1), url(${sectionInfo.images.background})`,
        }}>
            <h2 className="section-header gradient-header">Spotlight</h2>
            <h3 className='headline headline--center'>{sectionInfo.title}</h3>
            <div className='constrain-content'>
                <ul className='spotlight__list'>
                    {sectionInfo.spotlights.map((item, index) => {
                        if (item.type === 'External URL') {
                            return <SpotLightExternalLink item={item} key={`spotlight-${index}`} />
                        }
                        
                        if (item.type === 'Internal Article') {
                            return <SpotlightInternalLink item={item} key={`spotlight-${index}`} />
                        }
                        
                        return <SpotlightVideo item={item} key={`spotlight-${index}`} />                         
                    })}
                </ul>
            </div>
        </section>
    );
}

export default Spotlight;
