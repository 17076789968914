import React from 'react'
import { useQuery } from 'react-query'
import { getResource } from '../../models/ApiModel'
import SocialLinks from '../../components/SocialLinks'
import Working from '../../components/Working'

import './../../scss/Footer.scss'

function Footer() {
    const { isSuccess, isLoading, data} = useQuery(['footer', { resourceName: 'footer' }], getResource)

    const getLinks = (section) => {
        if (isSuccess) return data.nav_items.find((nav) => {
            return nav.section === section;
        })

        return {
            section: '',
            title: '',
            links: [],
        }
    }

    return (
        <footer className="main-footer">
            <div className="constrain-content">
                <div className='footer-logo-wrapper'>
                    <h2 className="secondary-logo">
                        <a href="/">
                            <span className="sr-only">Scripps Networks</span>
                        </a>
                    </h2>
                </div>
                { isLoading && <Working /> }
                { isSuccess && <>
                    <div className="footer-nav-wrapper footer-nav__brands">
                        <h5 className='footer-header'>Our Brands</h5>
                        { data.nav_items.length !== 0 && 
                            <ul className='footer-nav__list reset-list'>
                                {getLinks('brands')?.links.sort((a,b) => {
                                    return parseInt(a.menu_order) - parseInt(b.menu_order)
                                }).map((item, index) => {
                                    return (
                                        <li className="footer-nav__item" key={`footer-brands-nav-${index}`}>
                                            <a href={item.link} 
                                                className="footer-nav__link" 
                                                target={item.target}
                                            >{item.text}</a>
                                        </li>
                                    )    
                                })}
                            </ul>
                        }
                    </div>
                    <div className="footer-nav-wrapper footer-nav__privacy">
                        <h5 className='footer-header'>Privacy & Terms</h5>
                        { data.nav_items.length !== 0 && 
                            <ul className='footer-nav__list reset-list'>
                                { getLinks('privacy')?.links.sort((a,b) => {
                                    return parseInt(a.menu_order) - parseInt(b.menu_order)
                                }).map((item, index) => {
                                    return (
                                        <li className="footer-nav__item" key={`footer-brands-nav-${index}`}>
                                            <a href={item.link} 
                                                className="footer-nav__link" 
                                                target={item.target}
                                            >{item.text}</a>
                                        </li>
                                    )    
                                })}
                            </ul>
                        }
                    </div>
                    <div className="footer-nav-wrapper footer-nav__info">
                        <h5 className='footer-header'>Information</h5>
                        { data.nav_items.length !== 0 && 
                            <ul className='footer-nav__list reset-list'>
                                { getLinks('information')?.links.map((item, index) => {
                                        return (
                                            <li className="footer-nav__item" key={`footer-brands-nav-${index}`}>
                                                <a href={item.link} 
                                                    className="footer-nav__link" 
                                                    target={item.target}
                                                >{item.text}</a>
                                            </li>
                                        )    
                                })}
                            </ul>
                        }
                    </div>
                    <div className="footer-nav-wrapper footer-nav__corporate">
                        <h5 className='footer-header'>Corporate</h5>
                        { data.nav_items.length !== 0 && 
                            <ul className='footer-nav__list reset-list'>
                                { getLinks('corporate')?.links.map((item, index) => {
                                        return (
                                            <li className="footer-nav__item" key={`footer-brands-nav-${index}`}>
                                                <a href={item.link} 
                                                    className="footer-nav__link" 
                                                    target={item.target}
                                                >{item.text}</a>
                                            </li>
                                        )    
                                })}                            
                            </ul>
                        }
                    </div>
                    <div className="footer-nav-wrapper footer-nav__social">
                        <SocialLinks 
                            onClickHandler={() => true} 
                            size="0.7rem" 
                            showHeader
                            headerText={data.social_media?.header}
                            plaforms={data.social_media?.platforms} 
                        />                                        
                    </div>
                </>}
            </div>                
        </footer>
    )
}

export default Footer
