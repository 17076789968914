import React, { useState } from 'react'
import PageHeroAdvertising from './PageHeroAdvertising'
import PageHeroDistribution from './PageHeroDistribution'

import './../scss/PageHero.scss'

const initImage = (window.innerWidth <= 600) ? 'hero_mobile' : 'hero'

function PageHero({ resoure, page = 'advertising', brands = []}) {
    const [image, setImage] = useState(initImage)
    
    window.addEventListener('resize', () => {
        const image = (window.innerWidth <= 600) ? 'hero_mobile' : 'hero';

        setImage(image)
    })

    return (<>
        <header className={`page-hero-static`} style={{backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.75) 50%, rgba(0,0,0,0.55) 100%), url(${resoure.images[image]})`}}>
            {(page === 'advertising') &&
                <PageHeroAdvertising data={resoure} brands={brands} />
            }
            {(page === 'distribution') &&
                <PageHeroDistribution data={resoure} />
            }
            {/* dist-hero__img-wrap adds transparency to the image */ }
        </header>
    </>)
}

export default PageHero
