import React, { useContext } from 'react'
import { MainMenuContext } from '../contexts/MainMenuContext'
import { IconContext } from 'react-icons/lib';
import './../scss/MainNavButton.scss'
function MainNavButton({ isCloseBtn = false }) {
    const {isMenuActive, setIsMenuActive} = useContext(MainMenuContext)

    const toggleMenu = () => {
        setIsMenuActive(!isMenuActive)
    }

    return (
        <IconContext.Provider value={{ 
            className: "main-nav-btn__icon", 
            size: '2rem',
            fill: '#fff',
        }}>
            <button className={`main-nav-btn`} 
                onClick={toggleMenu} 
                data-state={(isCloseBtn) ? 'active' : 'inactive'}
                aria-controls="main-navigation"
                aria-expanded={(isCloseBtn) ? 'true' : 'false'}
            >
                <div className="main-nav-btn__icon" aria-hidden="true"></div>
                <div className="main-nav-btn__icon" aria-hidden="true"></div>
                <div className="main-nav-btn__icon" aria-hidden="true"></div>
                <div className="main-nav-btn__icon" aria-hidden="true"></div>
                <div className="main-nav-btn__icon" aria-hidden="true"></div>
                <div className="main-nav-btn__icon" aria-hidden="true"></div>
                <div className="main-nav-btn__icon" aria-hidden="true"></div>
                <div className="main-nav-btn__icon" aria-hidden="true"></div>
                <div className="main-nav-btn__icon" aria-hidden="true"></div>
                <span className="sr-only">Main Menu</span>
            </button>
        </IconContext.Provider>
    )
}

export default MainNavButton
