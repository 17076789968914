import React from 'react';
import SocialLinks from './SocialLinks'
import InlineLink from './InlineLink'

import './../scss/ContactItem.scss'
export default function ContactItem({ contact }) {
    return (
        <li className='contact-item'>
            <h4 className='contact-item__location'>{contact.location}</h4>
            <div className='contact-item__name'>{contact.name}</div>
            <div className='contact-item__company'>{contact.company}</div>
            <div className='contact-item__email'>
                <InlineLink link={`mailto:${contact.email}`} text={contact.email} /> 
            </div>
            <div className='contact-item__phone'>{contact.phone}</div>
            { (contact.social_links.length !== 0) && <SocialLinks platforms={contact.social_links} size='0.9rem'/> }
        </li>
    )
}
