import React, { useState, useRef } from 'react';
import Header from './partials/Header';
import Footer from './partials/Footer';
import CopyRight from './partials/CopyRight';
import MainNavigation from './partials/MainNavigation';
import Modal from '../components/Modal';
import VideoPlayer from '../components/VideoPlayer';
import ModalCarousel from '../components/ModalCarousel';
import Overlay from '../components/Overlay';
import { MainMenuContext } from '../contexts/MainMenuContext';
import { ModalContext } from '../contexts/ModalContext';
import { VideoPlayerContext } from '../contexts/VideoPlayerContext';
import { useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import FadeIn from '../components/FadeIn';
import MainNavButton from '../components/MainNavButton';

const BaseLayout = ({ children }) => {
    const [isMenuActive, setIsMenuActive] = useState(false)
    const [isModalActive, setIsModalActive] = useState(false)
    const [modalIsVideo, setModalIsVideo] = useState(true)
    const [bcId, setBcId] = useState('')
    const [videoPlayer, setVideoPlayer] = useState(null)
    const mainNavigationRef = useRef(null)

    let hasNews = useLocation().pathname.includes(`/news`)
    let hasBrand = useLocation().pathname.includes(`/brand`)
    let hasStations = useLocation().pathname.includes(`/stations`)
    let isSolidHeader =  hasNews && !hasBrand;
    if (hasStations) {
        isSolidHeader = true;
    }

    return (
       <React.Fragment>

        <ModalContext.Provider value={ {isModalActive, setIsModalActive, modalIsVideo, setModalIsVideo} }>
        <VideoPlayerContext.Provider value={ {bcId, setBcId, videoPlayer, setVideoPlayer} }>
            <FadeIn duration={450} delay={350}>
                <div className={(isSolidHeader) ? `content-wrapper content-wrapper--white` : `content-wrapper`}>
                    <Header isSolid={isSolidHeader} />
                    <main className="content">{children}</main>
                    <Footer />
                    <CopyRight />
                    <MainMenuContext.Provider value={ {isMenuActive, setIsMenuActive} }>
                        <MainNavButton isCloseBtn={isMenuActive} />
                        <CSSTransition
                            in={isMenuActive}
                            timeout={{enter: 425, exit: 325}}
                            classNames="main-nav"
                            onExited={() => mainNavigationRef.current.style.zIndex = 0}
                            onEnter={() => mainNavigationRef.current.style.zIndex = 100}
                        >

                                <MainNavigation ref={mainNavigationRef}/>

                        </CSSTransition>
                    </MainMenuContext.Provider>
                </div>
                <Modal>
                    {(modalIsVideo && isModalActive) && <VideoPlayer />}
                    {(!modalIsVideo && isModalActive) && <ModalCarousel />}
                </Modal>
                <Overlay/>
            </FadeIn>
        </VideoPlayerContext.Provider>
        </ModalContext.Provider>

    </React.Fragment>
    );
};
export default BaseLayout;
