import React from 'react';
import parse from 'html-react-parser'
import TextLink from './TextLink';

function ShowcaseExternal({ item }) {
    return (            
        <figure className='video-card'>
            <a href={item.url} target="_blank" rel="noreferrer" className='video-card__img-wrapper'>
                <img src={item.images.thumbnail} alt="" className='video-card__img'/>
                <div className='video-card__hover-background'></div>
            </a>
            <figcaption className='video-card__caption'>
                <div className='video-card__title'>{parse(item.title)}</div>
                { (item.description !== null) && 
                    <div className='video-card__description'>
                        {parse(item.description)}
                    </div>
                }
                <TextLink text="Learn More" type="readmore" link={item.url} linkType="external" />
            </figcaption>
        </figure>
    )
}

export default ShowcaseExternal;
