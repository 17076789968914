import React from 'react'

import './../scss/OurGroups.scss'

function OurGroups({ pageData }) {
    return (
        <section className='our-groups page-section-padding' style={{backgroundImage: `var(--primary-gradient-1), url(${pageData.images.background})`}}>
            <h2 className="section-header gradient-header">Divisions</h2>
            <h3 className="headline headline--center">{pageData.title}</h3>
            <div className='our-groups__wrapper constrain-content'>
                { pageData.groups.map((group, index) => {
                    return (
                        <a href={group.link} className='our-groups__link' key={`our-brands-${index}`}>
                            <figure className='our-groups__item'>
                                <img className='our-groups__img' src={group.images.thumbnail} alt={group.title} />
                                <figcaption className={`our-groups__title our-groups--${group.title}`}>{group.title}</figcaption>   
                            </figure>
                            <div className='our-groups__reveal'>
                                {group.description}
                            </div>
                        </a>
                )})}
            </div>
        </section>
    )
}

export default OurGroups
