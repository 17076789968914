import React, { useContext, useEffect, useRef } from 'react'
import { MainMenuContext } from '../../contexts/MainMenuContext'
import MainNavButton from '../../components/MainNavButton'
import SocialLinks from '../../components/SocialLinks'

import './../../scss/MainNavigation.scss'
import { useQuery } from 'react-query'
import { getResource } from '../../models/ApiModel'
import Working from '../../components/Working'

const MainNavigation = React.forwardRef((props, ref) => {
    const { isSuccess, isLoading, data} = useQuery(['mainNav', { resourceName: 'mainNav' }], getResource)
    const { isMenuActive, setIsMenuActive } = useContext(MainMenuContext)
    const navLogoLinkRef = useRef(null)

    const closeMenu = () => {
        setIsMenuActive(false);
    }

    useEffect(() => {
        let bodyElClassList = document.querySelector('body').classList;
        
        if (isMenuActive) {
            if (!bodyElClassList.contains('no-scroll')) bodyElClassList.add('no-scroll');
            navLogoLinkRef.current.focus()  
        } else {
            if (bodyElClassList.contains('no-scroll')) bodyElClassList.remove('no-scroll');
        }
    }, [isMenuActive])

    return (
        <>
            <nav ref={ref} className={isMenuActive ? `main-nav main-nav--active` : `main-nav`} id="main-navigation">
                <header className="main-nav__header">
                    <h2 className="nav-logo">
                        <a className="nav-logo__link" href="/" onClick={closeMenu} ref={navLogoLinkRef}>
                            <span className="sr-only">Scripps Networks</span>
                        </a>
                    </h2> 
                </header>
                { isLoading && <Working /> }
                { isSuccess && <ul className="main-nav__list">
                    { (data?.nav_items.length !== 0) && data.nav_items.sort((a,b) => {
                            return parseInt(a.menu_order) - parseInt(b.menu_order)
                        }).map((item, index) => {
                            return (
                                <li className="main-nav__item" key={`main-nav-${index}`}>
                                    <a href={item.url} 
                                        className="main-nav__link" 
                                        target={item.target} 
                                        onClick={closeMenu}
                                    >{item.text}</a>
                                </li>
                            )    
                        })
                    }
                    <li className="main-nav__item">
                        { isSuccess && <SocialLinks onClickHandler={closeMenu} showHeader headerText={data.social_media.header} platforms={data.social_media.platforms}/> }
                    </li>
                </ul> }
            </nav> 
        </>
    )
})

export default MainNavigation
