import React from 'react';
import StatItem from './StatItem';
import VideoPlayBtn from './VideoPlayBtn';

function PageHeroDefault({ data }) {
    return (
        <> 
            <div className='page-hero__content constrain-content'>
                <h2 className='page-hero__headline'>
                    <span className='sr-only'>{data.name}</span>
                    <img src={data.images.logo} alt={`${data.name} logo`} className='page-hero__logo' />
                </h2>
                { (data.stats.length !== 0) && 
                    <ul className='page-hero__stats'>
                        { data.stats.map((stat, index) => {
                            return <li className='page-hero__stat' key={`hero-stat-${index}`}>
                                <StatItem statValue={stat.value} statLabel={stat.label} />
                            </li>
                        })}
                    </ul>
                }
            </div>
            { (data.sizzle_reel !== null && data.sizzle_reel !== undefined) &&
                <VideoPlayBtn bcId={data.sizzle_reel} text="Sizzle Reel"/>
            }
        </>
    )
}

export default PageHeroDefault;
