import React from 'react'
import parse from 'html-react-parser'

function BrandProgrammingItem({ show }) {
  return (
    <div className='programming__link'>
        <div className='programming__item' style={{ backgroundImage: `url(${show.images.thumbnail})`,}} key={`show-${show.id}`}>                                    
            <div className='programming__content'>
                <div className='programming__init-name'>
                    {show.name}
                </div>
                <div className='programming__reveal brand-item__reveal'>
                    <div className='programming__reveal-name'>
                        {show.name}
                    </div>
                    <div className='brand-item__description'>
                        <p>{parse(show.short_description?.trim() ?? '')}</p>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
  )
}

export default BrandProgrammingItem