import React from 'react';

function DisplayError({ err }) {
    return (
        <section className='display-error'>
            The reported error is: {err?.message}
        </section>
    )
}

export default DisplayError;
