import React, { useEffect } from 'react'
import BannerNavItem from './BannerNavItem';

import './../scss/BannerNav.scss'

function BannerNav({ currentSlideIndex, changeSlide, navList = [], hoverEffect }) {
    useEffect(() => {
        document.querySelector('.banner-nav').style.setProperty('--multiplyer', `${100/navList.length}%`)
    }, [navList])

    return (
        <div className="banner-nav">
            <ul className="banner-nav__list reset-list" aria-controls="banner-carousel">
                {navList.length !== 0 && navList.map((item, index) => { 
                    return (
                        <BannerNavItem 
                            item={item} 
                            index={index}
                            currentSlideIndex={currentSlideIndex} 
                            changeSlide={changeSlide} 
                            hoverEffect={hoverEffect}
                            key={`banner-nav-item-${index}`}
                        />
                    );
                })}
            </ul>
        </div>
    )
}

export default BannerNav
