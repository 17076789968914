import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query';
import {useParams, useNavigate, useSearchParams, useLocation} from 'react-router-dom'
import { getResourceList } from '../models/ApiModel';
import { setMetaData } from '../utilities/SetMetaData'
import BrandStationsHero from '../components/BrandStationsHero';
import BrandStationsSearch from '../components/BrandStationsSearch';
import BrandStationsList from '../components/BrandStationsList';
import BrandStationsSelectedStation from '../components/BrandStationsSelectedStation';
import DisplayError from '../components/DisplayError';
import Working from '../components/Working';
import PageNotFound from './PageNotFound';

import './../scss/BrandStations.scss';

export default function Brand({ resourceName = 'brands' }) {
    const urlParams = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();

    const baseUrl = `${window.location.protocol}//${window.location.host}`;
    const baseBrandUrl = `${baseUrl}/brand/${urlParams.slug}`;
    const baseBrandStationUrl = `${baseBrandUrl}/stations`;

    // redirect to brand page if not ion --- only ion has stations list page
    if (urlParams.slug !== 'ion') {
        window.location = baseBrandUrl;
    }

    const updateUrlBasedOnSelectedStation = false;
    const selectedStationUrlKeyField = 'stationcall';
    const selectedStationUrlKeyParam = 'station';
    const selectedStationUrlValue = searchParams.get(selectedStationUrlKeyParam);
    const stationUrls = {
        'ion': 'https://api.scrippscloud.com/stations/v1/iontv?format=json&limit=100',
    };
    const initialStationData = {
        title: 'Stations',
        description: 'Applications filed with the FCC can be found by clicking a station row below.',
    };

    const [stationData, setStationData] = useState(initialStationData);
    const [stations, setStations] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [selectedStation, setSelectedStation] = useState(null);

    // get brand
    const { isSuccess, isLoading, isError, data, error } = useQuery([`${resourceName}Item:${urlParams.slug}`, {
        resourceName,
        resourceId: { slug: urlParams.slug },
        filters: {
            category: urlParams.slug,
        },
    }], getResourceList)

    // get brand stations
    const { data: dataStations } = useQuery([`${resourceName}Item:${urlParams.slug}:stations`], async ({ queryKey }) => {
        async function getStations(url){
            let response = await fetch(url);
            let resultStations = await response.json();
            return resultStations;
        }

        async function getResults(urlParam) {
            let records = [];
            let result = await getStations(urlParam);
            if (result.results.length > 0) {
                records = [...records, ...result.results];
            }
            if (result.next) {
                let nextUrl = result.next.replace('http://', 'https://').replace('affiliates', 'api').replace('/?', '?');
                let resultRecords = await getResults(nextUrl);
                if (resultRecords.length > 0){
                    records = [...records, ...resultRecords];
                }
            }
            return records;
        }

        const stationUrl = stationUrls?.[urlParams.slug] ?? '';
        let records = [];
        if (stationUrl) {
             records = await getResults(stationUrl);
        }
        return records;
    });

    // search handler
    const searchChangeHandler = (value) => {
        setSearchValue(value);
    }

    // select station handler
    const selectStationHandler = (station) => {
        setSelectedStation(station);
        window && window.scrollTo(0, 0);
        // selected station in url logic
        if (updateUrlBasedOnSelectedStation) {
            const basePathName = baseBrandStationUrl.replace(baseUrl, '');
            if(station){
                navigate({
                    pathname: basePathName,
                    search: `?${selectedStationUrlKeyParam}=${station?.[selectedStationUrlKeyField]}`
                }, {replace: false});
            }else{
                navigate({
                    pathname: basePathName
                }, {replace: false});
            }
        }
    }

    // update selected station based on location path changing
    useEffect(() => {
        // selected station in url logic
        if (updateUrlBasedOnSelectedStation) {
            if(selectedStationUrlValue) {
                let selectedStationFromUrl = stations.find(item => item?.[selectedStationUrlKeyField].toString().toLowerCase() === selectedStationUrlValue.toString().toLowerCase());
                if(selectedStationFromUrl && selectedStationFromUrl !== 1){
                    setSelectedStation(selectedStationFromUrl);
                }
            }else{
                setSelectedStation(null);
            }
        }
        // eslint-disable-next-line
    }, [location]);

    // set brand related data
    useEffect(() => {
        if (data !== undefined && data?.[0]?.name) {
            setMetaData({ title: `Scripps Networks - ${data[0].name} - Stations` })
            setStationData({
                title: `${data[0].name} ${initialStationData.title}`,
                description: `${data[0].name} ${initialStationData.description}`,
            });
        }
        // eslint-disable-next-line
    }, [data]);

    // set stations list
    useEffect(() => {
        if (JSON.stringify(dataStations) !== JSON.stringify(stations)) {
            setStations(dataStations);
        }

        // selected station in url logic
        if (updateUrlBasedOnSelectedStation) {
            if((!stations || (stations && stations.length === 0)) && dataStations && dataStations.length > 0 && selectedStationUrlValue){
                let selectedStationFromUrl = dataStations.find(item => item?.[selectedStationUrlKeyField].toString().toLowerCase() === selectedStationUrlValue.toString().toLowerCase());
                if(selectedStationFromUrl && selectedStationFromUrl !== 1){
                    setSelectedStation(selectedStationFromUrl);
                }
            }
        }
        // eslint-disable-next-line
    }, [dataStations, stations]);

    return (
        <>
            { isLoading  && <Working height={`40rem`} /> }
            { (isSuccess && data !== undefined) && <>
                <div className='constrain-content stations-container'>
                    {selectedStation ? <>
                            <BrandStationsSelectedStation
                                onSelectStationHandler={selectStationHandler}
                                station={selectedStation}
                            />
                        </> : <>
                        <BrandStationsHero stationHero={stationData} />
                        <BrandStationsSearch
                            value={searchValue}
                            onChangeHandler={searchChangeHandler}
                        />
                        <BrandStationsList
                            stations={stations}
                            stationData={stationData}
                            searchValue={searchValue}
                            onSelectStationHandler={selectStationHandler}
                        />
                    </>
                    }
                </div>
            </>}
            { (isSuccess && data === undefined) && <>
                <PageNotFound
                    className='page-not-found-bg-black'
                    message={`Sorry ${urlParams.slug} isn't one of our ${resourceName}.`}
                    page={{
                        name: (resourceName === 'brands') ? 'brands' : 'home',
                        path: (resourceName === 'brands') ? `${window.location.protocol}//${window.location.host}/#our-brands` : `${window.location.protocol}//${window.location.host}`,
                    }}
                />
            </>}
            { isError && <DisplayError err={error} /> }
        </>
    )
}
