import React, { useEffect } from 'react'
import bcPlayerLoader from '@brightcove/player-loader'
import { bcSettings } from '../settings/Settings'

function VideoPlayerOnPage({ bcId }) {
    useEffect(() => {
        bcPlayerLoader({
            refNode: `#video-player-${bcId}`,
            refNodeInsert: 'append',
            accountId: bcSettings.accountId,
            playerId: bcSettings.playerId,
            embedId: bcSettings.embedId,
            videoId: bcId,
        });
    }, [bcId]);

  return (
    <div id={`video-player-${bcId}`} className="video-wrapper"></div>
  )
}

export default VideoPlayerOnPage