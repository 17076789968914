import React from 'react'
import { distributionPlatformsTipText } from './../settings/Settings.js'

import './../scss/WatchOn.scss'

function WatchOn({ platforms = [] }) {
    return (
        <ul className='watch-on__list reset-list'>
            {platforms.map((platform, index) => {
                return (
                    <li className='watch-on__item' key={`watch-on-${index}`} {...{'data-tip-text': `${distributionPlatformsTipText[platform.name]}`}}>
                        <img src={platform.image.url} alt=""/>
                        <div>
                            {platform.name}
                            {platform.is_coming_soon && <div className='platform-coming-soon'>Coming Soon</div>}
                        </div>
                    </li>
                )
            })}
        </ul>
    )
}

export default WatchOn
