import * as ApiSettings from '../settings/WPApiSettings'

const ApiResourceList = ApiSettings.apiResources;

const addFilters = filters => {
    let paramsStr = '?';

    Object.keys(filters).forEach((key, index) => {
        paramsStr += `${(index) ? `&` : ``}${key}=${filters[key]}`
    })

    return paramsStr
}

const transformers = {
    homepage : data => {
        let item = data.homepage
        return {
            about: {
                title: item.about_section_title,
                description: item.about_section_description,
                images: {
                    background: item.about_section_background_image,
                },
                stats: item.about_section_stats,
            },
            ourBrands: {
                images: {
                    background: item.our_brands_section_background_image,
                },
            },
            ourGroups: {
                title: item.our_groups_section_title,
                images: {
                    background: item.our_groups_section_background_image
                },
                groups: [
                    {
                        title: 'Advertising',
                        link: '/advertising',
                        images: {
                            thumbnail: item.our_groups_section_advertising_image,
                        },
                        description: item.our_groups_section_advertising_hover_text,
                    },                        
                    {
                        title: 'Distribution',
                        link: '/distribution',
                        images: {
                            thumbnail: item.our_groups_section_distribution_image,
                        },
                        description: item.our_groups_section_distribution_hover_text,
                    },
                ],
            },
            recentNews: {
                description: item.recent_news_section_description,
                images: {
                    background: item.recent_news_section_background_image,
                },
            },
        };
    },
    brands: data => {
        let contact_info = transformers.resourceContactInfo(data)

        return data.map((brand) => {
            return {
                id: brand.id,
                name: brand.category[0].name,
                slug: brand.category[0].slug,
                menu_order: brand.menu_order,
                description_title: brand.description_title,
                short_description: brand.short_description,
                description: brand.description,
                about_section_title: brand.about_section_title,
                sizzle_reel: brand.sizzle_reel_bc_id,
                url: brand.url,
                images: {
                    hero: brand.hero_image,
                    hero_mobile: brand.hero_mobile_image,
                    thumbnail: brand.thumbnail_image,
                    logo: brand.logo,
                    logo_hover: brand.logo_hover,
                },                
                category: brand.category,
                watch_on: brand.tune_in?.map((item) => {
                    return {
                        name: item.tune_in,
                        image: {
                            url: item.tune_in_icon,
                        },
                        is_coming_soon: (item.tune_in_coming_soon) ? true : false,
                    }
                }) ?? [],
                tune_in: [],
                social_platforms: brand.social_media?.map((platform) => {
                    return {
                        platform: platform.social_media_type,
                        url: platform.social_media_url,
                    }
                }) ?? [],
                social_posts: [],
                stats: brand.stats?.map((stat) => {
                    return {
                        label: stat.stat_name,
                        value: stat.stat_value,
                    }
                }) ?? [],
                video_playlist_id: brand.brightcove_video_playlist_id,
                more_from: brand.more_brands_feature_section ?? [],
                contact_info,
                shows: brand.shows,
            }
        })
    },
    brand: data => {
        let contact_info = transformers.resourceContactInfo([data])

        return {
            id: data.id,
            name: data.category[0].name,
            slug: data.category[0]?.slug,
            menu_order: data.menu_order,
            description_title: data.description_title,
            short_description: data.short_description,
            description: data.description,
            about_section_title: data.about_section_title,
            sizzle_reel: data.sizzle_reel_bc_id,
            url: data.url,
            images: {
                hero: data.hero_image,
                hero_mobile: data.hero_mobile_image,
                thumbnail: data.thumbnail_image,
                logo: data.logo,
                logo_hover: data.logo_hover,
            },                
            category: data.category,
            watch_on: data.tune_in?.map((item) => {
                return {
                    name: item.tune_in,
                    image: {
                        url: item.tune_in_icon,
                    },
                    is_coming_soon: (item.tune_in_coming_soon) ? true : false,
                }
            }) ?? [],
            tune_in: [],
            social_platforms: data.social_media?.map((platform) => {
                return {
                    platform: platform.social_media_type,
                    url: platform.social_media_url,
                }
            }) ?? [],
            social_posts: [],
            stats: data.stats?.map((stat) => {
                return {
                    label: stat.stat_name,
                    value: stat.stat_value,
                }
            }) ?? [],
            video_playlist_id: data.brightcove_video_playlist_id,
            more_from: data.more_brands_feature_section ?? [],
            contact_info,
            shows: data.shows,
        }
    },
    hero_sliders: data => {
        let mainData = data.map(slide => {
            return {
                headline: slide.display_title,
                content: slide.description,
                button_text: slide.button_cta_text,
                link: {
                    text: slide.button_cta_url,
                    isExternal: slide.open_button_cta_url_new_tab,
                },
                images: {
                    full_size: {
                        url: slide.slider_image_fullsize,
                    },
                    mobile: {
                        url: slide.slider_image_mobile
                    }
                },
            }
        });

        let navData = data.map(slide => {
            return {
                title: slide.slider_menu_header,
                subtitle: slide.slider_menu_title,
            }
        });

        return {
            slides: mainData,
            navList: navData,
        }
    },
    posts: data => {
        return data.map((post) => {
            return {
                id: post.id,
                menu_order: post.menu_order,
                category: {
                    name: post.categories[0].display_name,
                    slug: post.categories[0].slug,
                },
                images: {
                    square_thumbnail: post.square_thumbnail_image,
                    rectangle_thumbnail: post.rectangle_thumbnail_image,
                },
                headline: post.headline,
                short_description: post.short_description,
                external_article_url: post.external_article_url,
                slug: post.slug,
                title: post.title,
                published_date: post.post_date,
                author: post.post_author,
                masthead: {
                    type: post.masthead_content_type,
                    image: post?.masthead_image ?? null,
                    video: (Object.keys(post).includes('masthead_bc_id')) ? {bc_id: post.masthead_bc_id} : null,
                    slides: post?.masthead_image_gallery ?? null, 
                },
                contacts: post.num_contacts,
                media_links: [
                    {
                        title: 'Download PDF Version',
                        url: 'https://some.com/path/to/file.pdf',
                        type: 'download',
                        icon_type: 'pdf',
                    },
                    {
                        title: 'Download Word Doc',
                        url: 'https://some.com/path/to/file.docx',
                        type: 'download',
                        icon_type: 'worddoc',
                    },
                    {
                        title: 'Download Press Release',
                        url: 'https://some.com/path/to/file.txt',
                        type: 'download',
                        icon_type: 'pr',
                    },
                    {
                        title: 'Generic Download',
                        url: 'https://some.com/path/to/file.pdf',
                        type: 'download',
                        icon_type: 'default',
                    },
                    {
                        title: 'View Photos',
                        url: 'https://some.com/api/endpoint',
                        type: 'display',
                        icon_type: 'gallery',
                    },
                    {
                        title: 'View Slideshow',
                        url: 'https://some.com/api/endpoint',
                        type: 'display',
                        icon_type: 'slideshow',
                    },
                    {
                        title: 'Play Video',
                        url: 'https://some.com/api/endpoint',
                        type: 'display',
                        icon_type: 'video',
                    },
                ],
                content: post.post_content,
            }
        })
    },
    post: (data, id) => {
        return {
            id: data[id].id,
            menu_order: data[id].menu_order,
            category: {
                name: data[id].categories[0].display_name,
                slug: data[id].categories[0].slug,
            },
            images: {
                square_thumbnail: data[id].square_thumbnail_image,
                rectangle_thumbnail: data[id].rectangle_thumbnail_image,
            },
            headline: data[id].headline,
            short_description: data[id].short_description,
            external_article_url: data[id].external_article_url,
            slug: data[id].slug,
            title: data[id].post_title,
            published_date: data[id].post_date,
            author: data[id].post_author,
            masthead: {
                type: data[id].masthead_content_type,
                image: data[id]?.masthead_image ?? null,
                video: data[id]?.masthead_bc_id ?? null,
                slides: data[id]?.masthead_image_gallery ?? null, 
            },
            contacts: data[id].num_contacts,
            media_links: null,
            content: data[id].post_content,
        }
    },
    shows: data => {
        let contact_info =transformers.resourceContactInfo(data)
        
        return data.map((show) => {
            let showSlug = transformers.getShowSlug(show)

            return {
                id: show.id,
                name: show.show_event_name,
                slug: showSlug?.slug,
                menu_order: show.menu_order,
                description_title: show.description_title,
                short_description: show.short_description,
                description: show.description,
                about_section_title: show.about_section_title,
                sizzle_reel: show.sizzle_reel_bc_id,
                url: show.url,
                disable_click: show.disable_click,
                images: {
                    hero: show.hero_image,
                    hero_mobile: show.hero_mobile_image,
                    thumbnail: show.thumbnail_image,
                    logo: show.logo,
                },                
                category: show.category,
                tune_in: (show.tune_in !== undefined) ? show.tune_in.map((item) => {
                    return {
                        name: item.tune_in,
                        image: {
                            url: item.tune_in_icon,
                        },
                        is_coming_soon: (item.tune_in_coming_soon) ? true : false,
                    }
                }) : [],
                watch_on: [],
                social_platforms: (show.social_media !== undefined) ? show.social_media.map((platform) => {
                    return {
                        platform: platform.social_media_type,
                        url: platform.social_media_url,
                    }
                }) : [],
                social_posts: [],
                stats: (show.stats !== undefined) ? show.stats.map((stat) => {
                    return {
                        label: stat.stat_name,
                        value: stat.stat_value,
                    }
                }) : [],
                video_playlist_id: show.brightcove_video_playlist_id,
                more_from: show?.more_brands_feature_section ?? [],
                contact_info,
            }
        })
    },
    specials: data => {
        const contact_info = transformers.resourceContactInfo(data)

        return data.map((special) => {
            return {
                id: special.id,
                name: special.show_event_name,
                menu_order: special.menu_order,
                description_title: special.description_title,
                short_description: special.short_description,
                description: special.description,
                about_section_title: special.about_section_title,
                sizzle_reel: special.sizzle_reel_bc_id,
                url: special.url,
                images: {
                    hero: special.hero_image,
                    hero_mobile: special.hero_mobile_image,
                    thumbnail: special.thumbnail_image,
                    logo: special.logo,
                },                
                category: special.category,
                tune_in: special.tune_in?.map((item) => {
                    return {
                        name: item.tune_in,
                        image: {
                            url: item.tune_in_icon,
                        },
                        is_coming_soon: (item.tune_in_coming_soon) ? true : false,
                    }
                }) ?? [],
                watch_on: special.watch_on?.map((item) => {
                    return {
                        name: item.tune_in,
                        image: {
                            url: item.tune_in_icon,
                        },
                        is_coming_soon: (item.tune_in_coming_soon) ? true : false,
                    }
                }) ?? [],
                social_platforms: special.social_media?.map((platform) => {
                    return {
                        platform: platform.social_media_type,
                        url: platform.social_media_url,
                    }
                }) ?? [],
                social_posts: [],
                stats: special.stats?.map((stat) => {
                    return {
                        label: stat.stat_name,
                        value: stat.stat_value,
                    }
                }) ?? [],
                video_playlist_id: special.brightcove_video_playlist_id,
                more_from: special?.more_brands_feature_section ?? [],
                contact_info,
            }
        })
    },
    advertising: data => {
        data = data['advertising-page']
        
        let spotlights = data.featured_spotlights?.map((sl) => {
            return {
                type: sl.type,
                title: sl.title,
                description: sl.description,
                url: sl?.url ?? null,
                category: (sl.category === null) ? null : {
                    slug: sl.category?.slug ?? sl.category[0]?.slug,
                    name: sl.category?.display_name  ?? sl.category[0]?.display_name,
                },
                images: {
                    thumbnail: sl.thumbnail_image,
                },
                brightcove_id: sl?.brightcove_id ?? null,
                partner_url: sl?.url ?? null,
                article_post_id: sl?.article_post_id ?? null,
            }
        }) ?? []        

        let showcases = data.featured_showcases?.map((sc) => {
                return {
                    type: sc.type,
                    title: sc.title,
                    description: sc.description,
                    url: sc?.url ?? null,
                    category: (sc.category === null) ? null : {
                        slug: sc.category?.slug ?? sc.category[0].slug,
                        name: sc.category?.display_name ?? sc.category[0].display_name,
                    },
                    images: {
                        thumbnail: sc.thumbnail_image,
                    },
                    brightcove_id: sc?.brightcove_id ?? null,
                    partner_url: sc?.url ?? null,
                }
        }) ?? []

        return {
            images: {
                hero: data.hero_section_background_image,
                hero_mobile: data?.hero_section_mobile_image ?? null,
            },
            hero_title: data.hero_section_title,
            hero_subtitle: data.hero_section_subtitle,
            showcase: {
                title: data.showcase_section_title,
                description: data.showcase_section_description,
                images: {
                    background: data.showcase_section_background_image,
                },
                showcases,
                
            },
            spotlight: {
                title: data.spotlight_section_title,
                images: {
                    background: data.spotlight_section_background_image,
                },
                spotlights,
            },
        }
    },
    distribution: data => {
        return {
            heroSubheadline: data['distribution-page'].hero_section_description,
            heroHeadline: data['distribution-page'].hero_section_title,
            images: {
                hero: data['distribution-page'].hero_section_background_image,
                hero_mobile: data['distribution-page']?.hero_section_mobile_image ?? null,
            },
            networks: {
                title: data['distribution-page'].networks_section_title,
                images: {
                    background: data['distribution-page'].networks_section_background_image,
                },
            },
        };
    },
    contacts: data => {
        if (Object.keys(data[0]).includes('contacts')) {
            return data.map(section => {
                return {
                    title: section.title,
                    contacts: section.contacts.map(contact => {
                        return {
                            id: contact.id,
                            menu_order: contact.menu_order,
                            location: contact.contact_location,
                            name: contact.contact_full_name,
                            email: contact.contact_email,
                            company: contact.contact_company_name,
                            phone: contact.contact_phone_number,
                            social_links: contact.social_media?.map((sl) => {
                                return {
                                        platform: sl.social_media_type,
                                        url: sl.social_media_url,
                                    }
                                }
                            ) ?? []
                        }
                    }), 
                }
            })    
        } else {
            return data.map(contact => {
                return {
                    id: contact.id,
                    menu_order: contact.menu_order,
                    location: contact.contact_location,
                    name: contact.contact_full_name,
                    email: contact.contact_email,
                    company: contact.contact_company_name,
                    phone: contact.contact_phone_number,
                    social_links: contact.social_media?.map((sl) => {
                        return {
                                platform: sl.social_media_type,
                                url: sl.social_media_url,
                            }
                        }
                    ) ?? [],
                }
            })
        }
    },
    resourceContactInfo: data => {
        const contactInfo = [];
        if (Object.keys(data[0]).includes('url')) {
            if (data[0].url !== null) contactInfo.push({
                href: (data[0].url.includes('http')) ? data[0].url : `https://${data[0].url}`,
                type: 'link',
                text: data[0].url,
            });
        }

        if (Object.keys(data[0]).includes('contact_email')) {
            if (data[0].contact_email !== null) contactInfo.push({
                href: data[0].contact_email,
                type: 'email',
                text: data[0].contact_email,
            });
        } 

        return contactInfo
    },
    getShowSlug: show => {
        return show.category.find(cat => {
            return cat.type === 'show'
        })
    },
    mainNav: data => {
        let nav_items = data.nav_items
        let social_media = {
            header: data.social_media_section_header,
            platforms: data.social_media.map(item => {
                return {
                    platform: item.social_media_type,
                    url: item.social_media_url,
                }
            })
        }

        return {
            nav_items,
            social_media,
        }
    },
    footer: data => {
        let sectionsToIdMap = {
            758: 'brands',
            771: 'privacy',
            775: 'corporate',
            778: 'information',
            736: 'brands',
            737: 'privacy',
            739: 'corporate',
            738: 'information',
        }

        let nav_items = data.nav_items.map(item => {
            return {
                section: sectionsToIdMap[parseInt(item.header_id, 10)] ?? '',
                title: item.header,
                links: item.nav_list.map(link => {
                    return {
                        link: link.url,
                        text: link.text,
                        menu_order: link.menu_order,
                        target: link.target,
                    }
                })
            }
        }) 

        let social_media = {
            header: data.social_media_section_header,
            platforms: data.social_media.map(item => {
                return {
                    platform: item.social_media_type,
                    url: item.social_media_url,
                }
            })
        }

        return {
            nav_items,
            social_media, 
        }
    }
};

const getResourceList = async ({ queryKey }) => {
    const [_key, { resourceName, ...queryParams }] = queryKey
    
    let url = ApiResourceList[resourceName];

    if (Object.keys(queryParams).includes('filters')) url += addFilters(queryParams.filters)
    
    let response = await fetch(url);
    let results = await response.json();

    //* trigger error page if no results were found
    if (results.length === 0) return undefined; 

    if (Object.keys(transformers).includes(resourceName)) 
        return transformers[resourceName](results)

    return results;
}

const getResource = async ({ queryKey }) => {
    const [_key, { resourceName, resourceId = null }] = queryKey
    let url = (resourceId !== null) ? `${ApiResourceList[resourceName]}/${resourceId.slug}` : `${ApiResourceList[resourceName]}`

    let response = await fetch(url);
    let result = await response.json();    
    
    if (Object.keys(transformers).includes(resourceName)) { 
        if (resourceName === 'post' ) return transformers[resourceName](result, resourceId.slug)
            
        return transformers[resourceName](result)
    }

    return result;
}

export {
    getResourceList,
    getResource,
    transformers,
};
