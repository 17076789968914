import React from 'react'
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn, FaYoutube } from 'react-icons/fa'
import { IconContext } from 'react-icons/lib';
import { generateRandomStr } from '../utilities/GenerateRandomStr';

import './../scss/SocialLinks.scss'

function SocialLinks({
    onClickHandler,
    size = '1.4rem',
    showHeader = false,
    headerText = 'Follow Us',
    platforms = [],
    platformssocial = [
        {
            platform: 'facebook',
            url: 'https://www.facebook.com/EWScrippsCo',
        },
        {
            platform: 'twitter',
            url: 'https://twitter.com/EWScrippsCo',
        },
        {
            platform: 'instagram',
            url: 'https://www.instagram.com/EWScrippsCo',
        },
        {
            platform: 'linkedin',
            url: 'https://www.linkedin.com/company/the-e-w-scripps-company',
        },
    ]
}) {
    if (platforms.length > 0) {
        platformssocial = platforms;
    }
    return (
        <IconContext.Provider value={{
            className: "social-link__icon",
            size,
        }}>
            <div className="social-links">
                { showHeader && <h4 className="social-links__header">{ headerText }</h4> }
                { platformssocial.map((item, index) => {
                    return (
                        <a href={item.url}
                            className="social-link"
                            onClick={onClickHandler}
                            target="_blank"
                            rel="noreferrer"
                            key={`social-link-${generateRandomStr(5)}`}
                        >
                            {(item.platform.toLocaleLowerCase() === 'facebook') &&
                                <><span className="sr-only">Facebook</span><FaFacebookF/></>
                            }
                            {(item.platform.toLocaleLowerCase() === 'twitter') &&
                                <><span className="sr-only">Twitter</span><FaTwitter/></>
                            }
                            {(item.platform.toLocaleLowerCase() === 'instagram') &&
                                <><span className="sr-only">Instagram</span><FaInstagram/></>
                            }
                            {(item.platform.toLocaleLowerCase() === 'linkedin') &&
                                <><span className="sr-only">LinkedIn</span><FaLinkedinIn/></>
                            }
                            {(item.platform.toLocaleLowerCase() === 'youtube') &&
                                <><span className="sr-only">YouTube</span><FaYoutube/></>
                            }
                        </a>
                )})}
            </div>
        </IconContext.Provider>
    )
}

export default SocialLinks
