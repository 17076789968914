import React from 'react'
import { useQuery } from 'react-query'
import parse from 'html-react-parser';
import { IconContext } from 'react-icons';
import { FaPlus } from 'react-icons/fa';
import { transformers } from './../models/ApiModel.js';
import { apiResources } from './../settings/Settings.js';
import SectionHeader from './SectionHeader';
import Working from './Working';

import './../scss/MoreFrom.scss'

const getBrandsList = async ({ queryKey }) => {
    const [_key, { moreFromList }] = queryKey
  
    const getBrand = async (brandId) => {
      let endpoint = `${apiResources.brands}/${brandId}`  
      let response = await fetch(endpoint)  
      let results = response.json()
  
      return results
    }
  
    let brandsList = [];
    
    for (const brand of moreFromList) {
        const b = await getBrand(brand.id)        
        brandsList = [...brandsList, transformers.brand(b[brand.id])]
    }
  
    return brandsList
  }

function MoreFrom({ list, brandId }) {
    const { isSuccess, isLoading, isError, data: brandsList } = useQuery(
        [`morefrom:${brandId}`, { moreFromList: list }], getBrandsList)

    return (
        <section className='more-from page-section-padding'>
            <SectionHeader title="More From Scripps Networks"/>
            <div className='constrain-content'>
                {isLoading && <Working />}
                {isError && <div></div>}
                <ul className='brands-list'>
                    {isSuccess && brandsList.map((item, index) => {
                        return (
                            <li className='brands-list__item' key={`our-brands-${index}`}>
                                <a className='brand-item' 
                                    style={{
                                        backgroundImage: `url(${item.images.thumbnail})`,
                                    }}
                                    href={`${window.location.protocol}//${window.location.host}/brand/${item.slug}`}
                                >
                                    <div className="brand-item__content">
                                        <h3 className="brand-item__init-logo">
                                            <img src={item.images.logo} alt={`logo for ${item.name}`}/>
                                            <span className='sr-only'>{item.name}</span>
                                        </h3>
                                        <div className="brand-item__reveal">
                                            <h3 className="brand-item__reveal-logo">
                                                <img src={item.images.logo} alt={`logo for ${item.name}`}/>
                                                <span className='sr-only'>{item.name}</span>
                                            </h3>
                                            <h4 className="brand-item__tagline">{parse(item.description_title)}</h4>
                                            <div className="brand-item__description">
                                                <p>{parse(item.short_description)}</p>
                                            </div>
                                        </div>
                                    </div> 
                                    <span className="brand-item__link">Learn More</span>
                                    <span className="brand-item__see-more">
                                        <IconContext.Provider value={{ 
                                            className: "brand-item__see-more-icon", 
                                            size: '0.5rem',
                                        }}>
                                            <FaPlus/> see more
                                        </IconContext.Provider>
                                    </span>
                                </a>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </section>
    )
}

export default MoreFrom
