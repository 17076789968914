import React from 'react';

function ArticleCarousel() {
    return (
        <div className='article-carousel__wrapper'>
            this is the article carousel component
        </div>
    )
}

export default ArticleCarousel;
