import React, { useContext, useEffect, useState } from 'react';
import { ModalContext } from '../contexts/ModalContext';
import { VideoPlayerContext } from '../contexts/VideoPlayerContext';
import { bcSettings } from '../settings/Settings.js'
import TextLink from './TextLink';

async function getVideo(videoId) {
    let response = await fetch(`https://edge.api.brightcove.com/playback/v1/accounts/${bcSettings.accountId}/videos/${videoId}`, {
        headers: {Accept: `application/json;pk=${bcSettings.policyKey}`}
    })
    
    return await response.json()
}

function SpotlightVideo({ item }) {
    const { setIsModalActive  } = useContext(ModalContext)
    const { setBcId } = useContext(VideoPlayerContext)
    
    const playVideo = (bcId) => {
        setBcId(bcId)
        setIsModalActive(true);
    }

    return (
        <li className='spotlight__item'  style={{
            backgroundImage: `url(${item.images.thumbnail})`,
        }}>
            <div className="spotlight__link" onClick={() => playVideo(item.brightcove_id)}>
                <div className='spotlight__content'>
                    { (item.category !== null) && <h6 className='spotlight__category'>{item.category.name}</h6> }
                    <h5 className='spotlight__title'>{item.title}</h5>
                </div>
                <TextLink clickHandler={() => playVideo(item.brightcove_id)} text="Watch Video" type="readmore" />
            </div>
        </li>
    )
}

export default SpotlightVideo;
