import React from 'react';

function BrandStationsSearch({ onChangeHandler = () => {}, value = '', label = 'Station search:' }) {

    const onChange = (event) => {
        let value = event.target.value;
        onChangeHandler(value);
    }

    return (
        <div className='stations-search'>
            {label && <div className='stations-search__title'>{label}</div>}
            <div className='stations-search__input_wrapper'>
                <input
                    type="text"
                    className='stations-search__input'
                    value={value}
                    onChange={onChange}
                />
            </div>
        </div>
    )
}

export default BrandStationsSearch;
