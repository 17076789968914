const apiStageUrl = 'https://stagingcms.scrippsnetworks.com/wp-json/sn/v1';
const apiProdUrl = 'https://cms.scrippsnetworks.com/wp-json/sn/v1';

const apiBaseUrl = (window.location.host === 'scrippsnetworks.com' || window.location.host === 'd2i6w8dnfapxfw.cloudfront.net' || window.location.host === 'www.scrippsnetworks.com' ) ? apiProdUrl : apiStageUrl;

const apiResources = {
    homepage: `${apiBaseUrl}/home-page`,
    brands: `${apiBaseUrl}/brands`,
    shows: `${apiBaseUrl}/shows-events`,
    specials: `${apiBaseUrl}/shows-events`,
    hero_sliders: `${apiBaseUrl}/hero-sliders`,
    posts: `${apiBaseUrl}/posts`,
    post: `${apiBaseUrl}/posts`,
    contacts: `${apiBaseUrl}/contacts`,
    distribution: `${apiBaseUrl}/distribution-page`,
    advertising: `${apiBaseUrl}/advertising-page`,
    brandpage: `${apiBaseUrl}/brand-page`,
    showspecialpage: `${apiBaseUrl}/show-event-page`,
    mainNav: `${apiBaseUrl}/header-menu`,
    footer: `${apiBaseUrl}/footer-menu`,
}

const navigations = {
    mainNav: [
        {
            text: 'Advertising',
            link: '/advertising',
            target: '_self',
        },
        {
            text: 'Our Brands',
            link: '/#our-brands',
            target: '_self',
        },
        {
            text: 'Distribution',
            link: '/distribution',
            target: '_self',
        },
        // {
        //     text: 'All News',
        //     link: 'https://scripps.com/press-releases/',
        //     target: '_self',
        // },
        {
            text: 'Company',
            link: 'https://scripps.com/company/about-scripps/',
            target: '_blank',
        },
        // {
        //     text: 'Investors',
        //     link: 'https://ir.scripps.com/',
        //     target: '_blank',
        // },
        {
            text: 'Careers',
            link: 'https://scripps.com/careers/find-a-job/',
            target: '_blank',
        },

    ],
    footerNavs: [
        {
            section: 'brands',
            title: 'Our Brands',
            links: [
                {
                    link: 'https://bouncetv.com',
                    text: 'Bounce',
                    target: '_blank',
                },
                {
                    link: 'https://www.bouncetv.com/xl/',
                    text: 'Bounce XL',
                    target: '_blank',
                },
                {
                    link: 'https://www.brownsugar.com/',
                    text: 'Brown Sugar',
                    target: '_blank',
                },
                {
                    link: 'https://courttv.com',
                    text: 'Court TV',
                    target: '_blank',
                },
                {
                    link: 'https://www.defytvnet.com/',
                    text: 'Defy',
                    target: '_blank',
                },
                {
                    link: 'https://grittv.com',
                    text: 'Grit',
                    target: '_blank',
                },
                {
                    link: 'https://iontelevision.com',
                    text: 'ION',
                    target: '_blank',
                },
                {
                    link: 'https://ionmystery.com',
                    text: 'ION Mystery',
                    target: '_blank',
                },
                {
                    link: 'https://ionplustv.com',
                    text: 'ION Plus',
                    target: '_blank',
                },
                {
                    link: 'https://laff.com',
                    text: 'Laff',
                    target: '_blank',
                },
                {
                    link: 'https://newsy.com',
                    text: 'Newsy',
                    target: '_blank',
                },
                {
                    link: 'https://truerealtv.com',
                    text: 'TrueReal',
                    target: '_blank',
                },
            ]
        },
        {
            section: 'privacy',
            title: 'Privacy & Terms',
            links: [
                {
                    link: 'https://scripps.com/privacy-policy/',
                    text: 'Privacy Policy',
                    target: '_self',
                },
                {
                    link: 'https://scripps.com/user-agreement/',
                    text: 'Terms of Use',
                    target: '_self',
                },
                {
                    link: 'https://scripps.com/privacy-center/',
                    text: 'Privacy Center',
                    target: '_self',
                },
            ]
        },
        {
            section: 'information',
            title: 'Information',
            links: [
                {
                    link: 'https://scripps.com/press-kit/',
                    text: 'Media Kit',
                    target: '_self',
                },{
                    link: 'https://scripps.com/contact/',
                    text: 'Contact Us',
                    target: '_self',
                },
            ]
        },
        {
            section: 'corporate',
            title: 'Corporate',
            links: [
                {
                    link: 'https://scripps.com/',
                    text: 'The E.W. Scripps Company',
                    target: '_self',
                },
                {
                    link: 'https://scripps.com/careers/find-a-job/',
                    text: 'Careers',
                    target: '_self',
                },
            ]
        },
    ],
}

const distributionPlatforms = [
    {
        abbr: 'OTA',
        tipText: 'Over the Air',
        image: 'https://cdn.scrippscloud.com/shared/web/images/snn_icon-OTA.png',
        definition: 'Over the Air',
    },
    {
        abbr: 'OOH',
        tipText: 'Out-of-Home',
        image: 'https://cdn.scrippscloud.com/shared/web/images/snn_icon-OOH.png',
        definition: 'Out-of-Home (elevators, airports, Fitness Channels, Healthcare)',
    },
    {
        abbr: 'MVPD',
        tipText: 'Multi-video platform delivery',
        image: 'https://cdn.scrippscloud.com/shared/web/images/snn_icon-MVPDs.png',
        definition: 'Multi-video platform delivery (cable, telco, satellite)',
    },
    {
        abbr: 'SVOD',
        tipText: 'Subscription Video On Demand',
        image: 'https://cdn.scrippscloud.com/shared/web/images/snn_icon-SVOD.png',
        definition: 'Subscription Video On Demand (any mobile or connected device)'
    },
    {
        abbr: 'OTT / FAST',
        tipText: 'Over the Top / Free ad-supported Streaming Television',
        image: 'https://cdn.scrippscloud.com/shared/web/images/snn_icon-OTT-FAST.png',
        definition: 'Over the Top / Free ad-supported Streaming Television (Apps / FAST Channels like Pluto, Roku Channel, Xumo, etc.)'
    },
    {
        abbr: 'Audio',
        tipText: 'Uses the on-air audio stream only',
        image: 'https://cdn.scrippscloud.com/shared/web/images/snn_icon-AUDIO.png',
        definition: 'Uses the on-air audio stream only (Alexa devices, satellite radio)'
    },
]

const distributionPlatformsTipText = {
    OTA: 'Over the Air',
    OOH: 'Out-of-Home',
    MVPDs: 'Multi-video platform delivery',
    SVOD: 'Subscription Video On Demand',
    'OTT/FAST': 'Over the Top / Free ad-supported Streaming Television',
    Audio: 'Uses the on-air audio stream only',
}

const newsCategoryColors = {
    advertising: 'rgba(46, 100, 255, 0.6)',
    distribution: 'rgba(255, 159, 46, 0.6)',
    'digital-media': 'rgba(71, 23, 128, 0.6)',
    'pr-release': 'rgba(46, 100, 255, 0.6)', //'rgba(134, 73, 0, 0.6)',
}

const bcSettings = {
    accountId: '6295989288001',
    playerId: 'XybqrkPfq',
    policyKey: "BCpkADawqM2DFy2xoX-WGNwekaWYnny4MDWONXqttMWQeQ6gy9tQwlTSdxMMO3dATVMl-rY8069POCA9n7JajBPqGSppuLPptTbL-WaE8C7kL-XJ_cE_P1AgJf1FKecvdYyjmanKh9I09gHo",
    embedId: 'default'
};

const mediaLinkIcons = {
    pdf: {
        off: 'https://cdn.scrippscloud.com/shared/web/images/media-type-icons/snn_icon-PDF_OFF.svg',
        hover: 'https://cdn.scrippscloud.com/shared/web/images/media-type-icons/snn_icon-PDF_OVER.svg',
    },
    worddoc: {
        off: 'https://cdn.scrippscloud.com/shared/web/images/media-type-icons/snn_icon-DOC_OFF.svg',
        hover: 'https://cdn.scrippscloud.com/shared/web/images/media-type-icons/snn_icon-DOC_OVER.svg',
    },
    pr: {
        off: 'https://cdn.scrippscloud.com/shared/web/images/media-type-icons/snn_icon-PR_OFF.svg',
        hover: 'https://cdn.scrippscloud.com/shared/web/images/media-type-icons/snn_icon-PDF_OVER.svg',
    },
    gallery: {
        off: 'https://cdn.scrippscloud.com/shared/web/images/media-type-icons/snn_icon-PHOTOS_OFF.svg',
        hover: 'https://cdn.scrippscloud.com/shared/web/images/media-type-icons/snn_icon-PHOTOS_OVER.svg',
    },
    slideshow: {
        off: 'https://cdn.scrippscloud.com/shared/web/images/media-type-icons/snn_icon-SLIDESHOW_OFF.svg',
        hover: 'https://cdn.scrippscloud.com/shared/web/images/media-type-icons/snn_icon-SLIDESHOW_OVER.svg',
    },
    video: {
        off: 'https://cdn.scrippscloud.com/shared/web/images/media-type-icons/snn_icon-WATCH_OFF.svg',
        hover: 'https://cdn.scrippscloud.com/shared/web/images/media-type-icons/snn_icon-WATCH_OVER.svg',
    },
    default: {
        off: 'https://cdn.scrippscloud.com/shared/web/images/media-type-icons/snn_icon-DOWNLOAD_OFF.svg',
        hover: 'https://cdn.scrippscloud.com/shared/web/images/media-type-icons/snn_icon-DOWNLOAD_OFF.svg',
    },
};

const blueBrandLogos = {
    ion: 'https://cdn.scrippscloud.com/shared/web/images/snn_logo-blue-Ion.svg',
    bounce: 'https://cdn.scrippscloud.com/shared/web/images/snn_logo-blue-Bounce.svg',
    newsy: 'https://cdn.scrippscloud.com/shared/web/images/snn_logo-blue-Newsy.svg',
    'court-tv': 'https://cdn.scrippscloud.com/shared/web/images/snn_logo-blue-CourtTV.svg',
    'ion-mystery': 'https://cdn.scrippscloud.com/shared/web/images/snn_logo-blue-ION-Mystery.svg',
    grit: 'https://cdn.scrippscloud.com/shared/web/images/snn_logo-blue-Grit.svg',
    laff: 'https://cdn.scrippscloud.com/shared/web/images/snn_logo-blue-Laff.svg',
    truereal: 'https://cdn.scrippscloud.com/shared/web/images/snn_logo-blue-TrueReal.svg',
    defy: 'https://cdn.scrippscloud.com/shared/web/images/snn_logo-blue-Defy.svg',
    'ion-plus': 'https://cdn.scrippscloud.com/shared/web/images/snn_logo-IONPlus_Blue.svg',
    'brown-sugar': 'https://cdn.scrippscloud.com/shared/web/images/snn_logo-BrownSugar_Blue.svg',
    'bounce-xl': 'https://cdn.scrippscloud.com/shared/web/images/snn_logo-BounceXL_Blue.svg',
}

export {
    apiBaseUrl,
    apiResources,
    navigations,
    bcSettings,
    newsCategoryColors,
    mediaLinkIcons,
    distributionPlatforms,
    blueBrandLogos,
    distributionPlatformsTipText,
}
