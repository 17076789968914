import React, { useContext } from 'react'
import { IconContext } from 'react-icons'
import { FiPlay } from 'react-icons/fi'
import { ModalContext } from '../contexts/ModalContext'
import { VideoPlayerContext } from '../contexts/VideoPlayerContext'

import './../scss/VideoPlayBtn.scss'
function VideoPlayBtn({ bcId,  size = '1.5rem', text, title }) {
    const { setIsModalActive  } = useContext(ModalContext)
    const { setBcId } = useContext(VideoPlayerContext)

    const handleClick = () => {
        setBcId(bcId)
        setIsModalActive(true);
    }
    
    return (
        <button type="button" className='video-play-btn'
            onClick={handleClick}  
            title={title}  
        >
            <IconContext.Provider value={{ 
                className: "video-play-btn__icon", 
                size,
            }}>                
                <FiPlay/>
            </IconContext.Provider>
            {text}
        </button>
    )
}

export default VideoPlayBtn
