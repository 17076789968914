import React, { useContext } from 'react'
import { ModalContext } from '../contexts/ModalContext'
import { VideoPlayerContext } from '../contexts/VideoPlayerContext'

import './../scss/Buttons.scss'

function CTAButton({ type, text, link = null }) {
    const { setIsModalActive  } = useContext(ModalContext)
    const { setBcId } = useContext(VideoPlayerContext)
    
    const playVideo = (linkText) => {
        if (linkText !== null) {
            setBcId(linkText.split(':')[1].trim())
            setIsModalActive(true);
        }
    }

    return (link !== null && link?.text?.includes('video:')) ? 
        (<button className={type === 'secondary' ? 'btn secondary-cta' : 'btn primary-cta'}
            onClick={() => playVideo(link.text)}
        >
            {text}
        </button>)
        :
        (<a  className={type === 'secondary' ? 'btn secondary-cta' : 'btn primary-cta'}
            href={link.text}
            target={(link.isExternal) ? `_blank` : ``}
        >
            {text}
        </a>)        
}

export default CTAButton
