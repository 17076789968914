import React from 'react';
import getNetworkLink from '../utilities/getNetworkLink';
import windowOpen from '../utilities/windowOpen';

function SocialShareButton({ children, network, url, text, longtext }) {
    const openShareWindow = (networkLink) => {
        windowOpen(networkLink)
    }
    
    const shareContent = (e) => {
        e.preventDefault();
        const networkLink = getNetworkLink({network, url, text, longtext})

        openShareWindow(networkLink)
    }

    return (
        <button
            className="social-share-button"
            aria-label={`Share on ${network}`}
            onClick={(e) => shareContent(e)}
        >
            {children}
        </button>
    )
}

export default SocialShareButton;
