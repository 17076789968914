import React from 'react'

import './../../scss/Copyright.scss'

function CopyRight() {
    
    return (
        <div className="copyright">
            &copy;{new Date().getFullYear()} Scripps Networks, part of E.W. Scripps Company. <span>All Rights Reserved.</span>
        </div>
    )
}

export default CopyRight
