import React from 'react'
import { useQuery } from 'react-query'
import { IconContext } from 'react-icons/lib'
import { getResourceList } from '../models/ApiModel'
import SectionHeader from './SectionHeader'
import Working from './Working'
import parse from 'html-react-parser'
import { FaPlus } from 'react-icons/fa'

import './../scss/OurBrands.scss'

function OurBrands({ pageData }) {
    const { isSuccess, isLoading, isError, data, error } = useQuery(['brands', {resourceName: 'brands'}], getResourceList)

    return (
        <section 
            className="our-brands page-section-padding"
            style={{
                backgroundImage: `url(${pageData.images.background})`,
            }}
        >
            <SectionHeader title="Our Brands"/>
            {isLoading && <Working height="1304px"/>}
            {isSuccess && 
                <div className='constrain-content'>
                    <ul className='brands-list'>
                        {data.sort((a,b) => {
                            return parseInt(a.menu_order) - parseInt(b.menu_order)
                        }).map((item, index) => {
                            return (
                                <li className='brands-list__item' key={`our-brands-${index}`}>
                                    <a className='brand-item' 
                                        style={{
                                            backgroundImage: `url(${item.images.thumbnail})`,
                                        }}
                                        href={`${window.location.protocol}//${window.location.host}/brand/${item.slug}`}
                                    >
                                        <div className="brand-item__content">
                                            <h3 className="brand-item__init-logo">
                                                <img src={item.images.logo} alt={`logo for ${item.name}`}/>
                                                <span className='sr-only'>{item.name}</span>
                                            </h3>
                                            <div className="brand-item__reveal">
                                                <h3 className="brand-item__reveal-logo">
                                                    <img src={item.images.logo} alt={`logo for ${item.name}`}/>
                                                    <span className='sr-only'>{item.name}</span>
                                                </h3>
                                                <h4 className="brand-item__tagline">{parse(item.description_title)}</h4>
                                                <div className="brand-item__description">
                                                    <p>{parse(item.short_description)}</p>
                                                </div>
                                            </div>
                                        </div> 
                                        <span className="brand-item__link">Learn More</span>
                                        <span className="brand-item__see-more">
                                            <IconContext.Provider value={{ 
                                                className: "brand-item__see-more-icon", 
                                                size: '0.5rem',
                                            }}>
                                                <FaPlus/> see more
                                            </IconContext.Provider>
                                        </span>
                                    </a>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            }
        </section>
    )
}

export default OurBrands
