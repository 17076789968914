import React from 'react';
import { blueBrandLogos } from './../settings/Settings'

function PageHeroAdvertising({ data, brands }) {
    return (
        <div className='static-page-hero__content constrain-content'>
            <h2 className='ad-page-hero__headline'>
                {data.hero_title}
            </h2>
            <h4 className='page-hero__subheadline'>{data.hero_subtitle}</h4>
            <div className="ad-page-hero__content-wrapper">
                <h6 className='ad-page-hero__headline'>Our Brands</h6>
                <ul className='ad-page-hero__brands-list'>
                    {brands.sort((a,b) => {
                        return parseInt(a.menu_order) - parseInt(b.menu_order)
                    }).map((brand, index) => {
                        return (
                            <li className={`ad-page-hero__brand-item`} key={`hero-brands-${index}`}>
                                <a className={`hero-brand-link hero-brand-link--${brand.slug}`} href={`/brand/${brand.slug}`}>
                                    <img src={brand.images.logo} alt={`${brand.name} Logo`}/>
                                    <div className='hero-brand-blue-wrapper'>
                                        <img src={brand.images.logo_hover ?? blueBrandLogos[brand.slug]} alt=""/>
                                    </div>
                                    <span className='sr-only'>{brand.name}</span>
                                </a>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </div>
    )
}

export default PageHeroAdvertising;
