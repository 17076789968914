import React from 'react'
import './../scss/InlineLinks.scss'

function InlineLink({ 
    link, 
    text, 
    target = '_blank', 
    title = null, 
    download = false,
    additionalClassname = ''
}) 
{
    return (
        <a href={link} 
            target={target}
            title={title}
            download={download}
            rel={(target === '_blank') ? `noreferrer` : undefined}
            className={(additionalClassname === '') ? `inline-link` : `inline-link ${additionalClassname}`}
        >
            {text}
        </a>
    )
}

export default InlineLink
