import React, { useEffect } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom';
import parse from 'html-react-parser'
import { format } from 'date-fns'
import { useLocation } from 'react-router-dom';
import { getResource } from '../../models/ApiModel'
import { setMetaData } from '../../utilities/SetMetaData';
import { truncateString } from '../../utilities/TruncateString';
import ArticleCarousel from '../../components/ArticleCarousel';
import ArticleImage from '../../components/ArticleImage';
import ArticleVideo from '../../components/ArticleVideo';
import ArticleContacts from '../../components/ArticleContacts';
import ArticleMediaLinks from '../../components/ArticleMediaLinks';
import SocialShare from '../../components/SocialShare';
import Working from '../../components/Working';
import PageNotFound from '../PageNotFound';

import './../../scss/NewsItem.scss'

const dateFormat = (date) => {
    // replace dashes in date string with slashes to get the correct date
    // see https://stackoverflow.com/questions/7556591/is-the-javascript-date-object-always-one-day-off
    return format(new Date(date.replace(/-/g, '/')), "MMMM d, Y");
}

export default function NewsItem() 
{
    const urlParams = useParams();
    const pathname = useLocation().pathname;
    const article = useQuery([`newsItem:${urlParams.id}`, {resourceName: 'post', resourceId: { slug: urlParams.id }}], getResource)  
    
    useEffect(() => {
        if (article?.isSuccess) setMetaData({
            title: `Scripps Networks - ${article.data.title}`,
            description: truncateString(article.data.content, 200),
        })
    }, [article])

    return (
        <section className='news-item'>
            { article.isLoading && <Working height={`20rem`} /> }
            { article.isError && <PageNotFound message={`Sorry we couldn't find that article.`} />}
            <div className='news-item__wrapper'>
                <article className='news-item__article constrain-content'>
                   { (article.isSuccess && article.data !== undefined) && <>
                        <header className='article__header'>
                            <h1 className='article__title'>
                                {article.data.title}
                            </h1>
                            <div className="article__date-author">
                                <span className='article__date'>{ dateFormat(article.data.published_date) }</span><span className='article__author'>By { article.data.author }</span>
                            </div>
                        </header>
                        { (article.data.masthead.type === 'Image') && 
                            <ArticleImage image={article.data.masthead.image} />
                        }
                        { (article.data.masthead.type === 'Video') && 
                            <ArticleVideo videoId={article.data.masthead.video} />
                        }
                        { (article.data.masthead.slides !== null) && 
                            <ArticleCarousel slides={article.data.masthead.slides} />
                        }
                        <main className="article__main-content">
                            {parse(article.data.content)}
                            {(article.data.contacts !== 0) && 
                                <ArticleContacts slug={article.data.slug} postId={article.data.id} />
                            }
                            {(article.data.media_links !== null) && 
                                <ArticleMediaLinks media={article.data.media_links} />
                            }
                        </main>
                    </>}
                    { (article.isSuccess && article.data === undefined) &&
                        <PageNotFound message={`Sorry we couldn't find the article you are looking for.`} page={{name: 'news', path:'/news'}} /> 
                    }
                </article>
                <aside className='news-item__aside'>
                    { (article.isSuccess && article.data !== undefined) && <SocialShare url={`${window.location.protocol}//${window.location.host}${pathname}`} text={article.data.title} longtext={article.data.short_description}/>}
                </aside>
            </div>
            {/* <MoreNews /> */}
        </section>
    )
}
