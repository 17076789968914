 import React from 'react'
 import parse from 'html-react-parser';
 import './../scss/StatItem.scss'

function StatItem({ statValue, statLabel }) {
    let labelArr = statLabel.split(" ")
    const formattedLabel = () => {
        let label = '';
        
        for (let i=0; i < labelArr.length; ++i) {
            if (i === 0) {
                label += `${labelArr[i]}<br/>`
            } else {
                label += `${labelArr[i]} `
            }
        }

        return label.trim();
    }

    return (
        <div className="stat-item">
            <div className="stat-item__value">{statValue}</div>
            <div className="stat-item__label">{parse(formattedLabel())}</div>
        </div>
    )
}

export default StatItem
