import React from 'react'

class GeneralErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) { 
        return {hasError: true}
  }

  componentDidCatch(error, errorInfo) {
   // you can log errors here
   console.error('Caught error: ', error)
   console.error('Error info: ', errorInfo)
  }

  render() {
      if (this.state.hasError) {
          return <div></div>
      }

      return this.props.children
    }
}

export default GeneralErrorBoundary