import React from 'react'

function BannerNavItem({ item, index, changeSlide, currentSlideIndex, hoverEffect }) {
    return (
        <li className={index === currentSlideIndex ? `banner-nav__item banner-nav__item--active` : `banner-nav__item`}>
            <button className='banner-nav__link' 
                onClick={() => changeSlide(index)}
                onMouseEnter={(e) => hoverEffect(e)}
                onMouseLeave={(e) => hoverEffect(e)}
                aria-label={`Featured Caraousel Item ${index + 1}`}
                aria-roledescription="Featured Carousel Item"
            >
                <span className='link__title'>{item.title}</span>
                <span className='link__subtitle'>{item.subtitle}</span>
            </button>
        </li>
    )
}

export default BannerNavItem
