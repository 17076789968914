import React from 'react'
import CTAButton from './CTAButton'
import parse from 'html-react-parser'

import './../scss/BannerSlide.scss'

function BannerSlide({ slide }) {
    return (
        <div className='banner-slide'>
            <div className='banner-slide__image-wrapper' style={{
                backgroundImage: `linear-gradient(to top, rgba(0,0,0,0.75) 0%,rgba(0,0,0,0) 60%), url(${slide.images.full_size.url})`
            }}>
                <div className="constrain-content">
                    <div className='banner-slide__content-wrapper'>
                        <h1 className='banner-slide__headline'>{parse(slide.headline)}</h1>
                        <p className='banner-slide__content'>{parse(slide.content)}</p>
                        { slide?.link?.text && 
                            <CTAButton text={slide.button_text} link={slide.link}/>
                        }
                    </div>
                </div>
            </div>
            <div className='banner-slide__image-wrapper banner-slide--mobile' style={{
                backgroundImage: `linear-gradient(to top, rgba(0,0,0,0.75) 0%,rgba(0,0,0,0) 60%), url(${slide.images.mobile.url})`
            }}>
                <div className="constrain-content">
                    <div className='banner-slide__content-wrapper'>
                        <h1 className='banner-slide__headline'>{parse(slide.headline)}</h1>
                        <p className='banner-slide__content'>{parse(slide.content)}</p>
                        { slide?.link?.text &&
                            <CTAButton text={slide.button_text} link={slide.link}/>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BannerSlide
