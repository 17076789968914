import React from 'react';
import { useQuery } from 'react-query';
import { getResourceList } from '../models/ApiModel';
import ContactItem from './ContactItem';
import SectionHeader from './SectionHeader';
import Working from './Working';

import './../scss/TeamContact.scss'

function TeamContact({ slug }) {
    const { isSuccess, isLoading, isError, data: contacts} = useQuery(['teamContacts', {resourceName: 'contacts', filters: {slug}}], getResourceList)
    
    return ( 
        <section className='team-contacts page-section-padding' id="contacts">
            <SectionHeader title="Contact Our Team" />
            { isLoading && <Working /> }
            { isError && <div></div>}
            { (isSuccess && (slug !== 'distribution-page')) &&
                <div className='constrain-content'>
                    <ul className="team-contacts__list reset-list">
                        { contacts.sort((a,b) => {
                            return parseInt(a.menu_order) - parseInt(b.menu_order)
                        })
                        .map((contact, index) => {
                            return (
                                <ContactItem contact={contact} key={`teamcontact-${index}`} />
                            )
                        })}
                    </ul>
                </div>
            }
            { (isSuccess && (slug === 'distribution-page')) &&
                <div className='constrain-content'>
                    {contacts.map(section => {
                        return (
                            <div className='team-contacts__section' key={`contact-section-${section.title}`}>
                                <h3 className='team-contacts__title'>{section.title}</h3>
                                <ul className="team-contacts__list reset-list">
                                    { section.contacts.sort((a,b) => {
                                        return parseInt(a.menu_order) - parseInt(b.menu_order)
                                    })
                                    .map((contact, index) => {
                                        return (
                                            <ContactItem contact={contact} key={`teamcontact-${index}`} />
                                        )
                                    })}
                                </ul>
                            </div>
                        )
                    })}                    
                </div>
            }
        </section>
    )
}

export default TeamContact;
