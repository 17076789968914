import React from 'react';
import ArticleDisplayMedia from './ArticleDisplayMedia';
import ArticleDownloadLink from './ArticleDownloadLink';

function ArticleMediaLink({ media }) {
    return (<>
        { (media.type === 'download') && 
            <ArticleDownloadLink item={media} /> }
    
        { (media.type === 'display') && 
            <ArticleDisplayMedia item={media} />
        } 
    </>)
}

export default ArticleMediaLink;
