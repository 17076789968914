const setMetaData = content => {
    const prefixes = ['og', 'twitter']

    const setCustomMeta = content => {
        Object.entries(content).forEach(pair => {
            prefixes.forEach(prefix => {
                document.querySelector(`meta[property="${prefix}:${pair[0]}"]`).setAttribute('content', pair[1])
            })
            
        })
    }
    
    const setUrlMeta = () => {
        prefixes.forEach(prefix => {
            document.querySelector(`meta[property="${prefix}:url"]`).setAttribute('content', `${window.location.protocol}//${window.location.host}${window.location.pathname}`)
        })
    }

    const setTitleTag = content => {
        document.title = (content.title !== '') ? content.title : 'Scripps Networks'
    }


    setCustomMeta(content)
    setUrlMeta()
    setTitleTag(content)
}

export { setMetaData };