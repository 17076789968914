import React from 'react'
import TextLink from './TextLink'

import './../scss/SectionHeader.scss'

function SectionHeader({title, description = null, button = null}) {
    return (
        <header className='section-header'>
            <div className='constrain-content'>
                <h2 className="section-header__title headline headline--thick">{title}</h2>
                {(description !== null) && 
                    <p className="section-header__description">
                        {description}
                    </p>
                }
                {(button !== null) && <TextLink text={button.text} link={button.link}/>}
            </div>
        </header>
    )
}

export default SectionHeader
