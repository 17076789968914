import React from 'react';
import SocialShareButton from './SocialShareButton';
import { FaEnvelope, FaFacebookF, FaLinkedin, FaTwitter } from 'react-icons/fa';

import './../scss/SocialShare.scss'
import { IconContext } from 'react-icons';

function SocialShare({ url, text, longtext, showHeader = true }) {
    const socailShareButtons = [
        { network: 'Facebook', icon: FaFacebookF },
        { network: 'Twitter', icon: FaTwitter },
        { network: 'Linkedin', icon: FaLinkedin },
        { network: 'Email', icon: FaEnvelope },
    ]

    return (

        <div className='social-share'>
            { showHeader && <h5 className='social-share__header'>Share:</h5> }
            {socailShareButtons.map((button) => {
                const {network, icon: Icon} = button

                return (
                    <SocialShareButton 
                        key={`social-share-${network}`}
                        network={network}
                        url={url}
                        text={text}
                        longtext={longtext}
                    >
                         <IconContext.Provider value={{ 
                            className: "social-share__icon", 
                            size: '1rem',
                        }}>
                            <Icon />
                        </IconContext.Provider>
                    </SocialShareButton>
                ) 
            })}
        </div>
    )
}

export default SocialShare;
