import he from 'he'

const truncateString = (str, length) => {
    // strip tags and decode html enitites
    let decodedStr = he.decode(str.replace(/(<([^>]+)>)/gi, ""))

    if ( decodedStr.length <= length ) return decodedStr

    return decodedStr.slice(0, length)
}

export { truncateString }