import React from 'react'
import parse from 'html-react-parser'
import GeneralErrorBoundry from './../error-boundries/GenralErrorBoundry'
import SocialLinks from './SocialLinks'
import InlineLink from './InlineLink'
import WatchOn from './WatchOn'
import TuneIn from './TuneIn'

import './../scss/ResourceInfo.scss'
function PageResourceInfo({ resource }) {
    const isIon = resource?.slug === 'ion';
    let fCCApplicationsLink = null;
    if (isIon) {
        fCCApplicationsLink = {
            link: `${window.location}/stations`,
            text: 'FCC Applications',
        };
    }
    return (
        <section className='resource-info'>
            <div className='constrain-content resource-info__content-wrapper'>
                <div className='resource-info__main'>
                    <h3 className='headline headline--black'>{resource.about_section_title}</h3>
                    <div className='resouce-info__content'>
                        <p>{parse(resource.description ?? '')}</p>
                    </div>
                    <ul className='resource-info__contacts reset-list'>
                        {resource.contact_info.map((contact, index) => {
                            return (
                                <li className='resource-info__contact' key={`team-contact-${index}`}>
                                    {contact.type === 'link' &&
                                        <InlineLink link={contact.href} text={contact.text} target="_blank" />
                                    }
                                    {contact.type === 'email' &&
                                        <InlineLink link={`mailto:${contact.text}`} text={contact.text} target="_blank" />
                                    }
                                </li>
                            )
                        })}
                        {fCCApplicationsLink && (
                            <li className='resource-info__contact'>
                                <InlineLink
                                    link={fCCApplicationsLink.link}
                                    text={fCCApplicationsLink.text}
                                    target='_self'
                                />
                            </li>
                        )}
                    </ul>
                </div>
                <div className='resource-info__share'>
                    <span className='resource-info__share-title'>Share: </span>
                    <GeneralErrorBoundry>
                        <SocialLinks platforms={resource.social_platforms} size="0.8rem" />
                    </GeneralErrorBoundry>
                </div>
                <div className='resource-info__side'>
                    { (resource.watch_on.length !== 0) &&
                        <>
                        <h2 className='gradient-header gradient-header--left'>Watch On</h2>
                        <WatchOn platforms={resource.watch_on}/>
                        </>
                    }
                    { (resource.tune_in.length !== 0) &&
                        <>
                        <h2 className='gradient-header gradient-header--left'>Tune In</h2>
                        <TuneIn list={resource.tune_in}/>
                        </>
                    }
                </div>
            </div>
        </section>
    )
}

export default PageResourceInfo
