import React from 'react';
import { useQuery } from 'react-query';
import { getResourceList } from '../models/ApiModel';
import Working from './Working';
import PlatformDefinitions from './PlatformDefinitions';

import './../scss/DistributionPlatforms.scss'

function DistributionPlatforms({ sectionInfo }) {
    const { isSuccess, isLoading, data } = useQuery(['brands', {resourceName: 'brands'}], getResourceList)

    return (
        <section className='dist-platforms page-section-padding' style={{
            backgroundImage: `var(--primary-gradient-1), url(${sectionInfo.images.background})`,
        }}>
            <h2 className="section-header gradient-header">Scripps Networks</h2>
            <h3 className='headline headline--center'>{sectionInfo.title}</h3>
            <div className='constrain-content'>
                <ul className='dist-platforms__brands-list reset-list'>
                    {isLoading && <Working />}
                    {isSuccess && <>
                        {data.map((brand) => {
                            return (
                                <li className='dist-platforms__item' key={`brand-${brand.slug}`}>
                                    <div className='dist-platforms__logo-wrapper'>
                                        <img src={brand.images.logo} alt="" />
                                        <span className='sr-only'>{brand.name}</span>
                                    </div>

                                    <div className={(brand.watch_on.length === 1) ? `dist-platforms__list dist-platforms__list--single` : `dist-platforms__list`}>
                                        {brand.watch_on.map((item) => {
                                            return (
                                                <div className='dist-platforms__platform' key={`${brand.slug}-${item.name}`}>
                                                    <img className='platform-logo' src={item.image.url} alt="" />
                                                    <div className='platform-name'>
                                                        {item.name}
                                                        {item.is_coming_soon && <div className='platform-coming-soon'>Coming Soon</div>}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </li>
                            )
                        })}
                    </>}
                </ul>
                <PlatformDefinitions />
            </div>
        </section>
    ) 
}

export default DistributionPlatforms;
