import React from 'react';

import './../scss/ErrorPages.scss'

function PageNotFound({ message = null, page = {name: 'home', path: '/'}, className = '' }) {
  return (
    <section className={`page-not-found${(className ? ` ${className}` : '')}`}>
        <h1>Page Not Found</h1>
        <div className='page-not-found__message'>
          { (message === null) && <>Sorry that page doesn't exist.</> }
          { (message !== null) && <>{message}</>}
        </div>
        <a href={page.path}>Go to the {page.name} page.</a>
    </section>
  )
}

export default PageNotFound;
