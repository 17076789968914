import React, { useEffect, useRef, useState } from 'react'
import { getResourceList } from "../models/ApiModel"
import Flickity from './FlickityFade'
import BannerSlide from './BannerSlide'
import { useQuery } from 'react-query'
import BannerNav from './BannerNav'
import BannerMobileNav from './BannerMobileNav'
import Working from './Working'

import './../scss/Flickity.scss'
import './../scss/FlickityFade.scss'

const autoPlayTime = 9000;

const flickityOptions = {
    fade: true,
    wrapAround: true,
    pageDots: false,
    arrows: false,
    freeScroll: false,
    autoPlay: autoPlayTime,
}

function BannerCarousel() {
    const [flickityObj, setFlickityObj] = useState(null);
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);    
    const { isSuccess, isLoading, isError, data } = useQuery(['heroSliders', {resourceName: 'hero_sliders'}], getResourceList)
    
    // set event handler when flickity instance is ready
    useEffect(() => {
        if (flickityObj !== null) {
            flickityObj.on('change', () => {
                setCurrentSlideIndex(flickityObj.selectedIndex)
            })
        }

        return () => { if (flickityObj !== null) flickityObj.off('change') }

    }, [flickityObj])

    useEffect(() => {
        if (flickityObj !== null) document.querySelector('.banner-nav').style.setProperty('--indicator-position', currentSlideIndex);
    }, [currentSlideIndex, flickityObj])

    const changeSlide = (index) => {
        if (flickityObj !== null) flickityObj.select(index);
    };

    const navHoverEffect = (e) => {
        if (flickityObj !== null) {
            if (e._reactName === 'onMouseLeave'  || e._reactName === 'onTouchEnd') flickityObj.unpausePlayer();
            if (e._reactName === 'onMouseEnter' || e._reactName === 'onTouchStart') flickityObj.pausePlayer();
        } 
    }

    return (
        <section className="banner-crousel" 
            style={{position: 'relative'}} 
            id="banner-carousel"
            aria-roledescription="carousel" 
            aria-label="Featured Carousel">
        { isLoading && <Working width="6rem" height="800px" />}
        { isError && <div style={{paddingTop: '8rem'}}></div>}
        { isSuccess && 
            <>
            <Flickity
                flickityRef={(c) => setFlickityObj(c)}
                className={'carousel'} // default ''
                elementType={'div'} // default 'div'
                options={flickityOptions} // takes flickity options {}
                disableImagesLoaded={false}
                static
            >
                {data.slides.map((slide, index) => {
                    return (
                        <BannerSlide slide={slide} key={`banner-slide-${index}`}/>
                    )
                })}
            </Flickity>
            <BannerNav navList={data.navList} currentSlideIndex={currentSlideIndex} changeSlide={changeSlide} hoverEffect={navHoverEffect}/>
            <BannerMobileNav navList={data.navList} currentSlideIndex={currentSlideIndex} changeSlide={changeSlide} hoverEffect={navHoverEffect}/>
            </>
        }
        </section>
    )
}

export default BannerCarousel
