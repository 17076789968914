const scrollToHash = (behavior = 'smooth') => {
    const href = window.location.href
    const hashIndex = href.split('').findIndex((char) => {
        return char === '#'
    })

    if (hashIndex === -1 ) return false

    const id = href.substring(href.length - (href.length - (hashIndex + 1 )));

    setTimeout(() => {
        const el = document.getElementById(id)
        const yCoordinate = (el !== null) ? el.getBoundingClientRect().top + window.pageYOffset : 0;
        
        window.scrollTo({ top: yCoordinate, behavior }); 
    }, 500)
    
}

export default scrollToHash