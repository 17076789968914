import React from 'react';
import { distributionPlatforms } from './../settings/Settings.js'

import './../scss/DistributionDefinitions.scss'

function PlatformDefinitions() {
    return (
        <section className='platform-definitions'>
            <h6 className='platform-definitions__header'>Definitions</h6>
            <div className='platform-definitions__content'>
                <ul className='platform-definitions__list'>
                 
                 {distributionPlatforms.map((platform) => {
                    return (
                        <li className='platform-definition reset-list' key={`pd-${platform.abbr}`}>
                            <img className='platform-definition__icon' src={platform.image} alt=""/>
                            <div className='platform-definition__content'>
                                <div className='platform-definition__abbr'>{platform.abbr}</div>
                                <div className='platform-definition__definition'>{platform.definition}</div>
                            </div>
                        </li>
                    )})
                }
                </ul>
            </div>
        </section>
    )
}

export default PlatformDefinitions;
