import React, { useState, useEffect } from 'react';

function BrandStationsList({ stations = [], stationData = {}, searchValue = '', onSelectStationHandler = () => {} }) {

    const stationsFields = [
        { key: 'stationcall', label: 'Station' },
        { key: 'channel', label: 'Channel' },
        { key: 'market_state', label: 'State' },
        { key: 'market', label: 'Market' },
    ];
    const [stationsFiltered, setStationsFiltered] = useState(stations);

    const [sort, setSort] = useState({
        key: null,
        order: 'asc'
    });

    useEffect(() => {
        let stationsFilteredNewState = [...stations];
        // filter by keyword
        if (searchValue) {
            stationsFilteredNewState = stations.filter((station) => {
                let searchKeyword = searchValue.toLowerCase();
                return stationsFields.some((item, index) => {
                    return station?.[item.key] && station[item.key].toString().toLowerCase().includes(searchKeyword);
                });
            });
        }
        // sort by fields
        let sortKey = sort.key;
        if (sortKey) {
            stationsFilteredNewState.sort((a, b) => {
                if (sort.order === 'asc') {
                    if (a[sortKey] < b[sortKey]) {
                        return -1;
                    }
                    if (a[sortKey] > b[sortKey]) {
                        return 1;
                    }
                    return 0;
                } else {
                    if (a[sortKey] < b[sortKey]) {
                        return 1;
                    }
                    if (a[sortKey] > b[sortKey]) {
                        return -1;
                    }
                    return 0;
                }
            });
        }
        setStationsFiltered(stationsFilteredNewState);
        // eslint-disable-next-line
    }, [stations, searchValue, sort]);

    const fieldSortClassName = (key) => {
        let classNames = ['sort'];
        if (key === sort.key) {
            if (sort.order === 'desc') {
                classNames.push('sort-active-desc');
            } else {
                classNames.push('sort-active-asc');
            }
        }
        return classNames.join(' ');
    }

    const onClickStation = (station = {}) => {
        onSelectStationHandler(station);
    }

    const handleKeyDown = (e, station = {}) => {
        if (e.key === 'Enter') {
            onClickStation(station);
        } else {
            return true
        }

    }

    const onClickFieldSort = (key = {}) => {
        if (key === sort.key) {
            setSort({
                key,
                order: sort.order === 'asc' ? 'desc' : 'asc'
            });
        } else {
            setSort({
                key,
                order: 'asc'
            });
        }
    }

    return (
        <div className='stations-list'>
            <div className='stations-list__mobile-title'>
                {stationData.title ?? 'Stations'}
            </div>
            <table className='stations-list__table'>
                <thead>
                <tr>
                    {stationsFields.map((item, index) => {
                        return (
                            <th
                                key={`stations-key-${index}`}
                                onClick={() => onClickFieldSort(item.key)}
                            >
                                {item.label} <i className={fieldSortClassName(item.key)}></i>
                            </th>
                        );
                    })}
                </tr>
                </thead>
                <tbody>
                {(stationsFiltered && stationsFiltered.length > 0) ? <>
                    {stationsFiltered.map((station, index) => {
                        return (
                            <tr
                                key={`stations-item-${index}`}
                                className='stations-list__item'
                                onClick={() => onClickStation(station)}
                                onKeyDown={(e) => handleKeyDown(e, station)}
                                tabIndex={0}
                            >
                                {stationsFields.map((item, index2) => {
                                    return (
                                        <td data-field={item.label} key={`stations-item-key-${index}-${index2}`}>
                                            {station?.[item.key] ?? ''}
                                        </td>
                                    );
                                })}
                            </tr>
                        )
                    })}
                </> : <>
                    <tr>
                        <td className='stations-list__no_match' colSpan={4}>
                            {searchValue ? 'There are no stations that match your search criteria.' : 'There are no stations found.'}
                        </td>
                    </tr>
                </>
                }
                </tbody>
            </table>
        </div>
    )
}

export default BrandStationsList;
