import React from 'react'
import CTAButton from '../components/CTAButton';
import InlineLink from '../components/InlineLink';
import TextLink from '../components/TextLink';

function AnimationPreview() {
    return (
        <>
            <div>
                CTA BUTTONs:<br/>
                <CTAButton text="Primary CTA"/><br/>
                <br/>
                <CTAButton type="secondary" text="secondary cta"/> 
            </div>
            <br/><br/>
            <div>
                Links:<br/> 
                <TextLink text="view all news" /><br/>
                <TextLink text="read more" type="readmore"/><br/>
                <TextLink text="watch more" type="readmore"/><br/>
                <TextLink text="learn more" type="readmore"/><br/>
            </div>
            <br/><br/>
            <div>
               Here's an inline link <InlineLink text="iontelevision.com" link="#"/> and something after it?
            </div>
            <br></br>
        </>
    )
}

export default AnimationPreview
