import React from "react"
import parse from 'html-react-parser'
import StatItem from "./StatItem"

import './../scss/AboutScripps.scss'

function AboutScripps({ pageData }) {    
    return (
        <section className="about-scripps page-section-padding">
            <h2 className="section-header gradient-header">About</h2>
            <div className="constrain-content">
                <h3 className="headline headline--center">{ pageData.title }</h3>
                <div className="about-scripps__content">
                    { parse(pageData.description) }
                </div>
                {(pageData.stats !== null) && <ul className="about-scripps__info-list">
                    {pageData.stats.map((info, index) => (
                        <li key={`info-${index}`} className="about-scripps__info-list-item">
                            <StatItem statValue={info.stat_value} statLabel={info.stat_name} />
                        </li>
                    ))}
                </ul>
                }
            </div>
        </section>
    )
}

export default AboutScripps
