import React, { useEffect } from 'react'
import { useQuery } from 'react-query'
import { setMetaData } from '../utilities/SetMetaData'
import DistributionPlatforms from '../components/DistributionPlatforms'
import PageHeroStatic from '../components/PageHeroStatic'
import TeamContact from '../components/TeamContact'
import Working from '../components/Working'
import { getResourceList } from '../models/ApiModel'
import scrollToHash from '../utilities/ScrollToHash'

export default function Distribution() {
    const { isSuccess, isLoading, data } = useQuery(['distributionPage', {resourceName: 'distribution'}], getResourceList)

    useEffect(() => {
        setMetaData({ title: `Scripps Networks - Distribution`})
        scrollToHash()
    }, [])

    return (<>
        {isLoading && <Working />}
        {isSuccess && <> 
            <PageHeroStatic resoure={data} page="distribution"/>
            <DistributionPlatforms sectionInfo={data.networks} />
        </>}
        <TeamContact slug="distribution-page"/>
    </>)
}
