import React from 'react';

const displayMeida = (e, url) => {
    e.preventDefault();
    console.log('do what you need to display this url content: ', url);
}

function ArticleDisplayMedia({ item }) {
    return (
        <div className='media-link__wrapper'>
            <span className={`article-icon article-icon--${item.icon_type}`}></span>
            <a 
                href={item.url}
                onClick={(e) => displayMeida(e, item.url)}
                className='article__media-link inline-link'
            >               
                {item.title}
            </a>
        </div>
    )
}

export default ArticleDisplayMedia;
