import React from 'react';
import ShowcaseItem from './ShowcaseItem';

import './../scss/Showcase.scss'

export default function Showcase({ sectionInfo }) {
    
    return (
        <section className='showcase page-section-padding' style={{
            backgroundImage: `url(${sectionInfo.images.background})`,
        }}>
            <h2 className="section-header gradient-header">Showcase</h2>
            <h3 className='headline headline--center headline--thick'>{ sectionInfo.title }</h3>
            <div className='showcase__description'>
                {sectionInfo.description}
            </div>
            <div className='constrain-content'>
                <ul className='showcase__list'>
                    {sectionInfo.showcases.map((item, index) => {
                        return (
                            <li className='showcase__item' key={`showcase-${index}`}>
                                <ShowcaseItem item={item} />
                            </li>        
                        )
                    })}
                </ul>
            </div>
        </section>
    )
}
