import React, { useContext, useEffect } from 'react'
import { ModalContext } from '../contexts/ModalContext'
import { VideoPlayerContext } from '../contexts/VideoPlayerContext';

import './../scss/Overlay.scss'

function Overlay() {
    const {isModalActive, setIsModalActive} = useContext(ModalContext);
    const {videoPlayer} = useContext(VideoPlayerContext)
    
    
    const toogleModal = () => {
        setIsModalActive(!isModalActive)
        if (videoPlayer !== null) videoPlayer.pause()
    }

    const handleKeyDown = e => {
        if (e.key === 'Escape') toogleModal()
    }

    useEffect(() => {
        const body = document.querySelector('body')
        let bodyElClassList = body.classList;
        let contentWrapperElClassList = (document.querySelector('.content-wrapper')) ? document.querySelector('.content-wrapper').classList : null;
        
        if (isModalActive) {
            if (!bodyElClassList.contains('no-scroll')) bodyElClassList.add('no-scroll');
            if (contentWrapperElClassList !== null) {
                if (!contentWrapperElClassList.contains('content-wrapper--blur')) contentWrapperElClassList.add('content-wrapper--blur');
                body.addEventListener('keydown', handleKeyDown, {
                    once: true,
                })
            }   
        } else {
            if (bodyElClassList.contains('no-scroll')) bodyElClassList.remove('no-scroll');
            if (contentWrapperElClassList !== null) {
                if (contentWrapperElClassList.contains('content-wrapper--blur')) contentWrapperElClassList.remove('content-wrapper--blur');
            }
        }
    }, [isModalActive])
    

    return (
        <div className={isModalActive ? `overlay overlay--active` : `overlay`} onClick={toogleModal} onKeyDown={handleKeyDown}></div>
    )
}

export default Overlay
