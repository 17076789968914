import React from 'react';

function PageHeroDistribution({ data }) {
    return (<>
        <div className='static-page-hero__content constrain-content'>
            <h2 className='dist-page-hero__headline'>
                {data.heroHeadline}
            </h2>
            <h4 className='page-hero__subheadline'>{data.heroSubheadline}</h4>               
        </div>
    </>)
}

export default PageHeroDistribution;
