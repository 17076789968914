import React, { useContext, useEffect } from 'react'
import bcPlayerLoader from '@brightcove/player-loader'
import { VideoPlayerContext } from '../contexts/VideoPlayerContext'
import { bcSettings } from '../settings/Settings'

import './../scss/VideoPlayer.scss'

function VideoPlayer() {
    const { bcId, setVideoPlayer, videoPlayer } = useContext(VideoPlayerContext)

    useEffect(() => {
        if (videoPlayer !== null) videoPlayer.reset()

        bcPlayerLoader({
            refNode: `#video-player-${bcId}`,
            refNodeInsert: 'append',
            accountId: bcSettings.accountId,
            playerId: bcSettings.playerId,
            embedId: bcSettings.embedId,
            videoId: bcId,
        })
        .then((success) => {
            setVideoPlayer(success.ref)
            success.ref.play()
        });
    }, [bcId, setVideoPlayer]); // do not add videoPlayer dependency, it creates an infinite loop

    return (
        <div id={`video-player-${bcId}`} className="video-wrapper"></div>
    )
}

export default VideoPlayer
