import React from 'react'

import './../../scss/MainHeader.scss'

function Header({ isSolid }) {
    
    return (        
        <header className={(isSolid) ? `main-header main-header--solid` : `main-header`}>
            <div className="constrain-content">
                <h1 className="main-logo">
                    <a className="main-logo__link" href="/">
                        <span className="sr-only">Scripps Networks</span>
                    </a>
                </h1>            
            </div>            
        </header>
    )
}

export default Header
