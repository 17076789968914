import React from 'react';
import { useQuery } from 'react-query';
import InlineLink from './InlineLink';
import { getResourceList } from '../models/ApiModel';
import Working from './Working';

import './../scss/ArticleContacts.scss'

function ArticleContacts({ slug, postId }) {
    const { isSuccess, isLoading, isError, data: contacts} = useQuery([`postContacts:${postId}`, {resourceName: 'contacts', filters: {slug}}], getResourceList)

    return (
        <div className='article-contacts'>
            {isLoading && <Working />}
            {isError && <div></div>}
            {isSuccess && contacts.map((contact, index) => {
                return (
                    <div className='contact' key={`article-contact-${index}`}>
                        <div className='contact__type'>{contact.location}:</div>
                        <div className='contact__name'>{contact.name}</div>
                        <div className='contact__company'>{contact.company}</div>
                        <div className='contact__phone'>{contact.phone}</div>
                        <div className='contact__email'>
                            <InlineLink link={`mailto:${contact.email}`} text={contact.email} />     
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default ArticleContacts;
