import React, { useEffect, useRef, useState } from 'react'
import { getResourceList } from '../models/ApiModel'
import { useQuery } from 'react-query'
import Flickity from 'flickity'
import ModalSlide from './BannerSlide'
import BannerNav from './BannerNav'
import Working from './Working'

import './../scss/Flickity.scss'

const flickityOptions = {
    wrapAround: true,
    pageDots: false,
    arrows: true,
    freeScroll: false,
    autoPlay: 4500,
}

function ModalCarousel() {
    return (
        <div>this is the modal carousel</div>
    )
}

export default ModalCarousel;
