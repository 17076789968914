import React from 'react'
import parse from 'html-react-parser'

import './../scss/TuneIn.scss'

function TuneIn({ list = [] }) {
    return (
        <ul className='tune-in__list reset-list'>
            {list.map((item, index) => {
                return (
                    <li className='tune-in__item' key={`tunein-${index}`}>
                        {parse(item.name)}
                    </li>
                )
            })}
        </ul>
    )
}

export default TuneIn
