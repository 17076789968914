import React, { useEffect } from 'react'
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom'
import { getResourceList } from '../models/ApiModel';
import { setMetaData } from '../utilities/SetMetaData'
import BrandProgramming from '../components/BrandProgramming';
import DisplayError from '../components/DisplayError';
import MoreFrom from '../components/MoreFrom';
import PageHero from '../components/PageHero';
import PageResourceInfo from '../components/PageResourceInfo';
import SocialCarousel from '../components/SocialCarousel';
import VideosList from '../components/VideosList';
import Working from '../components/Working';
import PageNotFound from './PageNotFound';

export default function Brand({ resourceName = 'brands' }) {
    const urlParams = useParams();
    const { isSuccess, isLoading, isError, data, error } = useQuery([`${resourceName}Item:${urlParams.slug}`, {
        resourceName, 
        resourceId: { slug: urlParams.slug },
        filters: {
            category: urlParams.slug,
        },
    }], getResourceList)

   
    useEffect(() => {
        if (data !== undefined) setMetaData({ title: `Scripps Networks - ${data[0].name}` })
    }, [data])
    

    return (
        <>
            { isLoading  && <Working height={`40rem`} /> }
            { (isSuccess && data !== undefined) && <>
                <PageHero resoure={data[0]}/>
                <PageResourceInfo resource={data[0]} />
                { (data[0].shows !== undefined) && <BrandProgramming brandSlug={data[0].slug} sectionInfo={data[0]} /> }
                { (data[0].video_playlist_id !== null && data[0].video_playlist_id !== undefined) && 
                    <VideosList 
                        playlistId={data[0].video_playlist_id} 
                        description={(data[0].video_section_description !== undefined) ? data[0].video_section_description : ''} /> 
                }
                { (data[0].social_posts.length !== 0) && <SocialCarousel resource={data[0]} /> }
                { (data[0].more_from.length !== 0) && <MoreFrom list={data[0].more_from} brandId={data[0].id} /> }
            </>}
            { (isSuccess && data === undefined) && <>
                <PageNotFound 
                    message={`Sorry ${urlParams.slug} isn't one of our ${resourceName}.`} 
                    page={{
                        name: (resourceName === 'brands') ? 'brands' : 'home', 
                        path: (resourceName === 'brands') ? `${window.location.protocol}//${window.location.host}/#our-brands` : `${window.location.protocol}//${window.location.host}`,
                    }} 
                />
            </>}
            { isError && <DisplayError err={error} /> }
        </>
    )
}
