import React from 'react'
import { FaPlus } from 'react-icons/fa'
import { IconContext } from 'react-icons/lib'
import parse from 'html-react-parser'
import { newsCategoryColors } from '../settings/Settings'

import './../scss/NewsSlide.scss'

function NewsSlide({ post }) {
    return (
        <div className='news-slide'>          
            <a className='news-slide__link' 
                style={{
                    backgroundImage: `url(${post.images.rectangle_thumbnail})`,
                }}
                href={(post.external_article_url !== null) ? `${post.external_article_url}`: `${window.location.protocol}//${window.location.host}/news/${post.id}`}
            target={(post.external_article_url !== null) ? `_blank`: `_self`}>
                <div className="brand-item__content">
                    <div className='news-slide__init-content'>
                        <h6 className="news-slide__category" style={{
                            backgroundColor: newsCategoryColors[post.category.slug] ?? `rgba(46, 100, 255, 0.6)`,
                        }}>
                            <span>{post.category.name}</span>
                        </h6>
                        <h5 className="news-slide__tagline news-slide__tagline-init">{parse(post.headline)}</h5>
                    </div>
                    <div className="brand-item__reveal">
                        <h6 className="news-slide__category"style={{
                            backgroundColor: newsCategoryColors[post.category.slug] ?? `rgba(46, 100, 255, 0.6)`,
                        }}>
                            <span>{post.category.name}</span>
                        </h6>
                        <h5 className="news-slide__tagline">{parse(post.headline)}</h5>
                        <div className="brand-item__description">
                            <p>{parse(post.short_description)}</p>
                        </div>
                    </div>
                </div> 
                <span className="brand-item__link">Read More</span>
                <span className="brand-item__see-more">
                    <IconContext.Provider value={{ 
                        className: "brand-item__see-more-icon", 
                        size: '0.5rem',
                    }}>
                        <FaPlus/> see more
                    </IconContext.Provider>
                </span>
            </a>
        </div>
    )
}

export default NewsSlide
