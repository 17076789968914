import React from 'react';
import ArticleMediaLink from './ArticleMediaLink';

import './../scss/ArticleLinks.scss'
import './../scss/ArticleMediaIcons.scss'

function ArticleMediaLinks({ media }) {
    return (<>
        <h5 className='media-links__header'>Media Links:</h5>
        <div className='media-links'>
            { media.map((item, index) => {
                return (
                    <ArticleMediaLink media={item} key={`article-media-link-${index}`} />
                )
            })}
        </div>
    </>)
}

export default ArticleMediaLinks;
