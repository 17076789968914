import * as Settings from './WPApiSettings';

const apiBaseUrl = Settings.apiBaseUrl;
const apiResources = Settings.apiResources;
const bcSettings = Settings.bcSettings;
const newsCategoryColors = Settings.newsCategoryColors;
const mediaLinkIcons = Settings.mediaLinkIcons;
const navigations = Settings.navigations;
const distributionPlatforms = Settings.distributionPlatforms;
const blueBrandLogos = Settings.blueBrandLogos;
const distributionPlatformsTipText = Settings.distributionPlatformsTipText;

export {
   apiBaseUrl,
   apiResources,
   navigations,
   bcSettings,
   newsCategoryColors,
   mediaLinkIcons,
   distributionPlatforms,
   blueBrandLogos,
   distributionPlatformsTipText,
};