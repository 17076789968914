const FadeIn = ({
    duration = 300,
    delay = 0,
    children,
    ...delegated
  }) => {
    return (
      <div
        className="fade-in__wrapper"
        {...delegated}
        style={{
          ...(delegated.style || {}),
          animationDuration: duration + 'ms',
          animationDelay: delay + 'ms',
        }}
      >
        {children}
      </div>
    );
  };

  export default FadeIn