import React, { useEffect } from 'react'
import { useQuery } from 'react-query'
import { setMetaData } from '../utilities/SetMetaData'
import PageHeroStatic from '../components/PageHeroStatic'
import Showcase from '../components/Showcase'
import Spotlight from '../components/Spotlight'
import TeamContact from '../components/TeamContact'
import Working from '../components/Working'
import { getResourceList } from '../models/ApiModel'
import scrollToHash from '../utilities/ScrollToHash'

export default function Advertising({ page }) {
    const { isSuccess, isLoading, isError, data } = useQuery(['advertising', {resourceName: 'advertising'}], getResourceList)

    const { 
        isSuccess: isBrandsSuccess, 
        isLoading: isBrandsLoading, 
        isError: isBrandsError, 
        data: brands 
    } = useQuery(['brands', {resourceName: 'brands'}], getResourceList)

    useEffect(() => {
        setMetaData({ title: `Scripps Networks - Advertising`})
        scrollToHash()
    }, [])

    return (
        <>
            { isLoading && <Working /> }
            { (isSuccess && isBrandsLoading) && <Working /> }
            { (isSuccess && isBrandsSuccess) && 
                <PageHeroStatic 
                    resoure={data} 
                    brands={brands} 
                    page="advertising"
                /> 
            }  
            { (isSuccess && isBrandsError) && <div style={{paddingTop: '8rem'}}></div> }       
            { (isSuccess && data.spotlight.spotlights.length !== 0) && <Spotlight sectionInfo={data.spotlight} />}
            { (isSuccess && data.showcase.showcases.length !== 0) && <Showcase sectionInfo={data.showcase} /> }
            { isError && <div></div>}
            <TeamContact slug="advertising-page"/>
        </>
    )
}
