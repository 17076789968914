import React, { useEffect, useState } from 'react'
import SectionHeader from './SectionHeader'
import VideoCard from './VideoCard'
import { generateRandomStr } from '../utilities/GenerateRandomStr'
import { bcSettings } from './../settings/Settings.js'

import './../scss/VideosList.scss'

async function getPlaylist(playlistId) {
    let response = await fetch(`https://edge.api.brightcove.com/playback/v1/accounts/${bcSettings.accountId}/playlists/${playlistId}?limit=54`, {
        headers: {Accept: `application/json;pk=${bcSettings.policyKey}`}
    })
    
    return await response.json()
}

function VideosList({ playlistId = null, description = '' }) {
    const [videos, setVideos] = useState([])
    
    useEffect(() => {
        getPlaylist(playlistId).then(data => {
            setVideos(data.videos)
        });
    },[playlistId, setVideos])

    return (
        <section className='videos page-section-padding'>
            <SectionHeader title="Videos" 
                description={description}
            />
            <div className='constrain-content'> 
                <div className='videos__grid'>
                 { (videos?.length >= 1) && videos.map((video) => {
                        return <VideoCard video={video} key={`video-${generateRandomStr(6)}`} />
                    })}
                </div>
            </div>            
        </section>
    )
}

export default VideosList
