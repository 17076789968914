import React from 'react';
import TextLink from './TextLink';

function SpotLightExternalLink({ item }) {
    return (
        <li className='spotlight__item'  style={{
            backgroundImage: `url(${item.images.thumbnail})`,
        }}>
            <a className="spotlight__link" href={item.url} target="_blank" rel="noreferrer">
                <div className='spotlight__content'>
                    { (item.category !== null) && <h6 className='spotlight__category'>{item.category.name}</h6> }
                    <h5 className='spotlight__title'>{item.title}</h5>
                </div>
                <TextLink type="readmore" text="View More" />
            </a>
        </li>
    )
}

export default SpotLightExternalLink;
