import React, { useEffect, useState } from 'react';
import { bcSettings } from '../settings/Settings';
import VideoCard from './VideoCard';

async function getVideo(videoId) {
    let response = await fetch(`https://edge.api.brightcove.com/playback/v1/accounts/${bcSettings.accountId}/videos/${videoId}`, {
        headers: {Accept: `application/json;pk=${bcSettings.policyKey}`}
    })
    
    return await response.json()
}

function ShowcaseVideo({ item }) {
    const [video, setVideo] = useState('')
    
    useEffect(() => {
        getVideo(item.brightcove_id).then(data => {
            setVideo(data)
        });
    },[item, setVideo])

    return (
        <>
            <VideoCard 
                video={
                    {
                        description: item.description,
                        id: item.brightcove_id,
                        custom_fields: {
                            networks_site_video_title: item.title,
                        },
                        partner_url: item.partner_url,
                        image: {
                            url: item.images.thumbnail,
                        },
                        poster: video.poster,
                    }
                } 
                partnerUrl={item.partner_url} 
                description={item.description}/>
        </>
    )
}

export default ShowcaseVideo;
