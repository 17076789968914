import React, { useContext } from 'react'
import { FaTimes  } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import { ModalContext } from '../contexts/ModalContext';
import { VideoPlayerContext } from '../contexts/VideoPlayerContext';

import './../scss/Modal.scss'

function Modal({ children }) {
    const {isModalActive, setIsModalActive} = useContext(ModalContext)
    const {videoPlayer} = useContext(VideoPlayerContext)

    const toggleModal = () => {
        setIsModalActive(!isModalActive)
        videoPlayer.pause()
    }

    return (
        <div className={isModalActive ? `modal modal--active` : `modal`}>
            <IconContext.Provider value={{ 
                className: "modal__close-btn-icon", 
                size: '2.5rem',
            }}>
                <button className="modal__close-btn"
                    onClick={toggleModal}
                    type="button"                
                    title="Close"
                >
                    <FaTimes/>
                </button>
            </IconContext.Provider>
            {children}
        </div>
    )
}

export default Modal
