import React from 'react';
import InlineLink from './InlineLink';

function ArticleDownloadLink({ item }) {
    return (
        <div className='media-link__wrapper'>
            <span className={`article-icon article-icon--${item.icon_type}`}></span>
            <InlineLink 
                link={item.url}
                text={item.title} 
                title={item.title} 
                additionalClassname='article__media-link'
                download={true} 
                target="_self"
            />               
        </div>
    )
}

export default ArticleDownloadLink;
