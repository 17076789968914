import React from 'react';

function ArticleImage({ image }) {
    return (
        <div className='article-img__wrapper'>
            <img src={image} alt="" className='article-img'/>
        </div>
    )
}

export default ArticleImage;
