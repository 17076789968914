import React from 'react'
import './../scss/TextLinks.scss'

function TextLink({ text = 'read more', link = null, linkType = null, type = 'largelink', clickHandler = null }) {
    return (
        <>
            { (link !== null) && 
                <a className={type === 'readmore' ? 'text-link text-link--read-more' : 'text-link'}
                    target={linkType === 'external' ? '_blank' : '_self'}
                    rel={linkType === 'external' ? 'noreferrer' : null}
                    href={link}
                >
                    {text}
                </a>
            }
            {
                (clickHandler !== null) && 
                <button className={type === 'readmore' ? 'text-link text-link--read-more' : 'text-link'}
                    type="button" 
                    onClick={clickHandler}
                >
                    {text}
                </button>
            }
            {
                (clickHandler === null && link === null ) && 
                <span className={type === 'readmore' ? 'text-link text-link--read-more' : 'text-link'}>
                    {text}
                </span>
            }
        </>
    )
}

export default TextLink
