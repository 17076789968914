import React, { Fragment } from 'react';
import parse from 'html-react-parser';
import { format } from 'date-fns';
import { FiChevronsLeft } from "react-icons/fi";

function BrandStationsSelectedStation({ station = null, onSelectStationHandler = () => {} }) {
    const backText = 'Back to Full Stations List'
    const stationTitleField = 'stationcall'
    /**
     * Sort filings by start_date in descending order
     * if start_date is the same, sort by end_date in decending order
     */
    const sortedFilings = station.fcc_filings.sort((a, b) => {
        if (a.start_date === b.start_date) {
            if (a.end_date === b.end_date) return 0
            return (a.end_date > b.end_date) ? 1 : -1
        }
        return (a.start_date > b.start_date) ? -1 : 1
    })

    const analogChannel = (station.channel_analog !== '') ? '(RF CHANNEL' + station.channel_analog + ')' : ''
    const today = new Date()

    const onClickBackToList = (station = {}) => {
        onSelectStationHandler(null);
    }

    return (
        <div className='station'>
            <button
                className='station__back-btn'
                onClick={onClickBackToList}
            >
                <FiChevronsLeft /> {backText}
            </button>
            {station ? <>
                    <h2 className='station__header'>{station && station?.[stationTitleField].toString().toUpperCase()} Station Information</h2>
                    <div className='station__description'>
                        {station ? <>
                            <div className='station__row'>
                                <div className='station__cell station__cell-title'>
                                    Station:
                                </div>
                                <div className='station__cell'>
                                   {station.market}
                                </div>
                            </div>
                            <div className='station__row'>
                                <div className='station__cell station__cell-title'>
                                    Call Letters:   
                                </div>
                                <div className='station__cell'>
                                   {station.stationcall} (<a className="inspection-file-link" href={`https://publicfiles.fcc.gov/tv-profile/${station.stationcall}`}>Online Public Inspection File</a>)
                                </div>
                            </div>
                            <div className='station__row'>
                                <div className='station__cell station__cell-title'>
                                    Channel:
                                </div>
                                <div className='station__cell'>
                                   {station.channel}
                                </div>
                            </div>
                            <div className='station__row'>
                                <div className='station__cell station__cell-title'>
                                    Last Updated On:
                                </div>
                                <div className='station__cell'>
                                   {format(new Date(station.updated_at), 'MMMM d, yyyy')}
                                </div>
                            </div>
                            <div className='station__row'>
                                <div className='station__cell station__cell-title'>
                                    Online Public Notice:
                                </div>
                                <div className='station__cell'>
                                    {/* has active filing */}
                                    { (station.fcc_filings?.length > 0 && (new Date(sortedFilings?.[0]?.start_date) >= today && new DataTransfer(sortedFilings?.[0]?.end_date) <= today)) && 
                                        <>
                                            On {format(new Date(sortedFilings[0].start_date), 'MMMM do, yyyy')}, {sortedFilings[0].applicant_name}, licensee of {station.stationcall}, CHANNEL {station.channel} {analogChannel}, {station.community_of_license}, filed an application with the Federal Communications Commission for Renewal of License. Members of the public wishing to view this application or obtain information about how to file comments and petitions on the application can visit publicfiles.fcc.gov, and search in <a className='inline' href={`https://publicfiles.fcc.gov/tv-profile/${station.stationcall}`}> public file</a>. 
                                        </>
                                    }
                                    {/* has filings but none are active */}
                                    { (station.fcc_filings?.length > 0 && !(new Date(sortedFilings[0].start_date) >= new Date() && new DataTransfer(sortedFilings[0].end_date) <= new Date()))  && 
                                        <>
                                            This station has no pending applications subject to the posting requirement.
                                        </>
                                    }
                                    {/* has no filings */}
                                    { (station.fcc_filings?.length === 0)  && 
                                        <>
                                            This station has no pending applications subject to the posting requirement.
                                        </>
                                    }
                                </div>
                            </div>
                            <div className='station__row'>
                                <div className='station__cell station__cell-title'>
                                    EEO Compliance:
                                </div>
                                <div className='station__cell'>
                                   {parse(station.content)}
                                </div>
                            </div>
                            <div className='station__row'>
                                <div className='station__cell station__cell-title'>
                                    Closed Caption:
                                </div>
                                <div className='station__cell'>
                                    <p>
                                        <strong>For immediate closed captioning assistance, please contact your local station:</strong>
                                    </p>
                                    <p>
                                        Tel: 888-467-2988
                                    </p>
                                    <p>
                                        Email: <a href="mailto:scrippscaptioning@scripps.com">scrippscaptioning@scripps.com</a>
                                    </p>
                                    <br />
                                    <p>
                                        We will make every effort to respond or otherwise resolve your inquiry within 24 hours or 1 business day.
                                    </p>
                                    <br />
                                    <p>
                                        <strong>For written complaints, please contact:</strong>
                                    </p>
                                    <p>David Giles</p>
                                    <p>VP, Deputy General Counsel, Chief Ethics Officer</p>
                                    <br />
                                    <p>The E.W. Scripps Company</p>
                                    <p>312 Walnut Street</p>
                                    <p>Suite 2800</p>
                                    <p>Cincinnati, OH 45202</p>
                                    <br />
                                    <p>Tel: 888-467-2988</p>
                                    <br />
                                    <p>Email: <a href="mailto:scrippscaptioning@scripps.com">scrippscaptioning@scripps.com</a></p>
                                    <br />
                                    <p>
                                        Before sending a written complaint, we recommend you first contact us via phone or email. We may be able to resolve your problem immediately. In any event, we will respond to your written complaint within 30 days.
                                    </p>
                                </div>
                            </div>
                        </> : <>
                            <div className='stations-selected-station__no_station'>
                                There is no station info for the selected station.
                            </div>
                        </>}
                    </div>
                </> : <>
                    <div className='stations-selected-station__title'>There selected station was not found.</div>
                </>
            }

            <button
                className='station__back-btn'
                onClick={onClickBackToList}
            >
                <FiChevronsLeft /> {backText}
            </button>
        </div>
    )
}

export default BrandStationsSelectedStation;
