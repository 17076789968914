import * as ApiModel from './BaseApiModel';

const getResource = ApiModel.getResource;
const getResourceList = ApiModel.getResourceList;
const transformers = ApiModel.transformers;

export {
    getResourceList,
    getResource,
    transformers,
};
