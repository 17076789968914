import React, { useEffect, useState } from 'react'
import NewsSlide from './NewsSlide'
import SectionHeader from './SectionHeader'
import Flickity from 'react-flickity-component'
import SlidingCarouselNav from './SlidingCarouselNav'
import { useQuery } from 'react-query'
import { getResourceList } from '../models/ApiModel'
import Working from './Working'

import './../scss/Flickity.scss'
import './../scss/RecentNews.scss'

const flickityOptions = {
    wrapAround: false,
    pageDots: false,
    arrows: false,
    freeScroll: true,
    autoPlay: false,
    cellAlign: 'left',
    initialIndex: 0,
    contain: false,
}
function RecentNews({ pageData }) {
    const { isSuccess, isLoading, isError, data, error } = useQuery(['recentNews', {resourceName: 'posts'}], getResourceList)
    const [flickityObj, setFlickityObj] = useState(null);
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
    let flickityViewportEl = document.querySelector('.recent-news .carousel .flickity-viewport');
    //let recentNewsNavEl = document.querySelector('.sliding-carousel-nav');
    
    // set event handler when flickity instance is ready 
    useEffect(() => {
        if (flickityObj !== null) {
            flickityObj.on('change', () => {
                setCurrentSlideIndex(flickityObj.selectedIndex)
            })
        }
    }, [flickityObj])

    // remove the shadow on left side when we reach the end
    useEffect(() => {
        if (flickityObj !== null) {
            if (currentSlideIndex >= data.length - 1) {
                if ( !flickityViewportEl.classList.contains('flickity-viewport--at-end'))
                    flickityViewportEl.classList.add('flickity-viewport--at-end')
            } else {
                if ( flickityViewportEl.classList.contains('flickity-viewport--at-end'))
                    flickityViewportEl.classList.remove('flickity-viewport--at-end')
            }
        }
    }, [currentSlideIndex, flickityViewportEl, flickityObj, data])

    const changeSlide = (index) => {
        setCurrentSlideIndex(index);   
        if (flickityObj !== null) flickityObj.select(index);
    }

    return (
        <section className='recent-news page-section-padding' style={{ backgroundImage: `url(${pageData.images.background})`}}>
            <SectionHeader title={`Recent News`} 
                description={pageData.description}
                // button={{text: 'View All News', link:'https://scripps.com/press-releases/'}}
            />
            { isLoading && <Working width="6rem" height="40rem" />}
            { isSuccess && 
                <div className='constrain-content-left'>
                    <Flickity
                        flickityRef={(c) => setFlickityObj(c)}
                        className={'carousel'} 
                        elementType={'div'}
                        options={flickityOptions}
                        disableImagesLoaded={false}
                        static
                    >
                        {data.map((post, index) => {
                            return (
                                <NewsSlide post={post} key={`news-slide-${index}`}/>
                            )
                        })}
                    </Flickity>
                    <SlidingCarouselNav max={data.length - 1} onChangeHandler={changeSlide} currentSlideIndex={currentSlideIndex} />
                </div>
            }
        </section>
    )
}

export default RecentNews
