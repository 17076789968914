import React from 'react'
import { IconContext } from 'react-icons';
import parse from 'html-react-parser'
import { FaPlus } from 'react-icons/fa';

function BrandProgrammingItemLink({ show }) {
  return (
    <a href={`${window.location.protocol}//${window.location.host}/show/${show.slug}`} className='programming__link' key={`show-${show.id}`}>
        <div className='programming__item' style={{ backgroundImage: `url(${show.images.thumbnail})`,}}>                                    
            <div className='programming__content'>
                <div className='programming__init-name'>
                    {show.name}
                </div>
                <div className='programming__reveal brand-item__reveal'>
                    <div className='programming__reveal-name'>
                        {show.name}
                    </div>
                    <div className='brand-item__description'>
                        {parse(show.short_description ?? '')}
                    </div>
                </div>
                
            </div>
            <span className="brand-item__link">Learn More</span>
            <span className="brand-item__see-more">
                <IconContext.Provider value={{ 
                    className: "brand-item__see-more-icon", 
                    size: '0.5rem',
                }}>
                    <FaPlus/> see more
                </IconContext.Provider>
            </span>
        </div>
    </a>
  )
}

export default BrandProgrammingItemLink