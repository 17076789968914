import React, { useEffect, useState } from 'react'
import Flickity from 'react-flickity-component'


const navFlickityOptions = {
    wrapAround: true,
    pageDots: false,
    arrows: false,
    freeScroll: false,
    autoPlay: false,
    cellAlign: 'left',
}

function BannerMobileNav({ currentSlideIndex, changeSlide, navList = [], hoverEffect }) {
    const [navFlickityObj, setNavFlickityObj] = useState(null);

    const clickHandler = index => {
        changeSlide(index)
        if (navFlickityObj !== null) {
            navFlickityObj.options.wrapAround = true
        } 
    }
    
    useEffect(() => {
        if (navFlickityObj !== null)  navFlickityObj.select(currentSlideIndex)
    }, [currentSlideIndex, navFlickityObj])

    return (
        <div className='banner-mobile-nav'>
            <Flickity
                    flickityRef={(c) => setNavFlickityObj(c)}
                    className={'mobile-nav-carousel'} // default ''
                    elementType={'div'} // default 'div'
                    options={navFlickityOptions} // takes flickity options {}
                    disableImagesLoaded={true}
                    static
                >
                    {navList.length !== 0 && navList.map((item, index) => {
                        return (
                            <div className={index === currentSlideIndex ? `banner-nav__item banner-nav__item--active` : `banner-nav__item`} key={`mobile-banner-nav-item-${index}`}>
                                <button className='banner-nav__link' 
                                    onClick={() => clickHandler(index)}
                                    onTouchStart={(e) => hoverEffect(e)}
                                    onTouchEnd={(e) => hoverEffect(e)}
                                >
                                    <span className='link__title'>{item.title}</span>
                                    <span className='link__subtitle'>{item.subtitle}</span>
                                </button>
                            </div>
                        )
                    })}
                </Flickity>
        </div>
    )
}

export default BannerMobileNav