import React from 'react';

function BrandStationsHero({ stationHero = {} }) {
    return (
        <div className='stations-hero'>
            <div className='stations-hero__title'>{stationHero.title}</div>
            {stationHero.description ?
                <div className='stations-hero__description'>{stationHero.description}</div>
                : ''
            }
        </div>
    )
}

export default BrandStationsHero;
