import React from 'react'

import './../scss/SlidingCarouselNav.scss'
import './../scss/CustomRangeInput.scss'

function SlidingCarouselNav({ onChangeHandler, currentSlideIndex, min = 0, max }) {

    const reportNewValue = (event) => {
        let index = event.target.valueAsNumber;
        
        onChangeHandler(index)
    }

    return (
        <nav className='sliding-carousel-nav'>
            <input type="range" 
                min={min} 
                max={max} 
                onChange={reportNewValue}
                value={currentSlideIndex} 
            />
        </nav>
    )
}

export default SlidingCarouselNav
