import React, { useEffect } from 'react'
import { useQuery } from 'react-query';
import { getResource } from '../models/ApiModel';
import AboutScripps from '../components/AboutScripps';
import BannerCarousel from '../components/BannerCarousel';
import OurBrands from '../components/OurBrands';
import OurGroups from '../components/OurGroups';
import RecentNews from '../components/RecentNews';
import scrollToHash from '../utilities/ScrollToHash';

export default function Home() {
    const { isSuccess, data } = useQuery(['homePage', {resourceName: 'homepage'}], getResource)

    useEffect(() => {
        scrollToHash()
    }, [])

    return (
        <>
        <BannerCarousel/>
        {isSuccess && 
            <> 
                <AboutScripps pageData={data.about}/> 
                <span id="our-brands"></span>
                <OurBrands pageData={data.ourBrands}/>
                <OurGroups pageData={data.ourGroups}/> 
                <RecentNews pageData={data.recentNews}/>         
            </>
        }
        </>
    )
}
